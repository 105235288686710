import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

interface IRouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isCheckout?: boolean;
  isRedirect?: boolean;
  isAuthenticated?: boolean;
  component: React.ComponentType;
}

const Route = ({
  isPrivate = false,
  isCheckout = false,
  isRedirect = false,
  isAuthenticated = false,
  component: Component,
  ...res
}: IRouteProps): JSX.Element => {
  const { token } = useAuth();

  return isPrivate === !!token ||
    isCheckout ||
    isRedirect ||
    isAuthenticated ? (
    // eslint-disable-next-line react/jsx-indent
    <ReactDOMRoute {...res} render={() => <Component />} />
  ) : (
    <Redirect to="/redirect" />
  );
};

export default Route;
