import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import CardList, { ICard, IGatewayUserData } from '../CardList';
import Payment from '../Payment';

export interface ICardPaymentData {
  checkout: IGatewayUserData;
  paymentType: string;
}

const CardPayment = ({
  checkout,
  paymentType,
}: ICardPaymentData): JSX.Element => {
  const [cards, setCards] = useState<ICard[]>([]);

  return (
    <>
      <Grid item xs={12}>
        <CardList
          cards={cards}
          setCards={setCards}
          gatewayUserData={checkout}
        />
      </Grid>
      <Grid item xs={12}>
        <Payment
          maxInstallments={
            paymentType === 'CREDIT' ? checkout.maxInstallments || 1 : 0
          }
          gateway={checkout.gateway}
          documents={checkout.documents}
        />
      </Grid>
    </>
  );
};

export default CardPayment;
