import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import Button from '../../../../components/Button';

import TableEstorno from '../../../../components/Table';
import Loading from '../../../../components/Loading/index';
import BoxShadow from '../../../../components/BoxShadow/index';

import JpMorganReportService from '../../../../services/report/JpMorganReportService';
import SuggestionsServices from '../../../../services/sugestions/SugestionsService';

interface IJpMorganSellerIdData {
  sellerId: string;
}

const JpMorganReport = (): JSX.Element => {
  const { t } = useTranslation('report');
  const snackbar = useSnackbar();

  const jpColumns = [
    { id: 'reportTypeName', label: 'Name' },
    { id: 'reportStatus', label: 'Status' },
    { id: 'createTimestamp', label: 'Created At' },
    { id: 'updateTimestamp', label: 'Updated At' },
    { id: 'intervalParam', label: 'Interval' },
    { id: 'reportDetails', label: 'File' },
  ];

  const [reports, setReports] = useState([]);
  const [openDialogReport, setOpenDialogReport] = useState(false);
  const [sellerId, setSellerId] = useState('');
  const [frequency, setFrequency] = useState('adhoc');
  const [weekDay, setWeekDay] = useState('');
  const [selectedJPMorganInicialDate, setSelectedJPMorganInicialDate] =
    useState<Date | null>(null);
  const [selectedJPMorganFinalDate, setSelectedJPMorganFinalDate] =
    useState<Date | null>(null);
  const [sellerIds, setSellerIds] = useState<IJpMorganSellerIdData[]>([]);
  const [jpReportInitialTime, setJpReportInitialTime] = useState<Date>(
    new Date(),
  );
  const [loading, setLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);

  const frequencies = ['adhoc', 'daily', 'weekly'];
  const weekDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const fetchJpMorganReportData = () => {
    JpMorganReportService.list().then((r) => {
      setReports(r.data.body.reports);
      setLoading(false);
    });
  };

  const handleJPMorganDateChangeInicial = (date: Date | null) => {
    setSelectedJPMorganInicialDate(date);
  };

  const handleJPMorganDateChangeFinal = (date: Date | null) => {
    setSelectedJPMorganFinalDate(date);
  };

  const handleDownloadJpMorganReport = (url: string) => {
    snackbar.enqueueSnackbar(
      `Iniciando download de relatório ${url.split('/').pop()}`,
      {
        variant: 'info',
        autoHideDuration: 3500,
      },
    );
    JpMorganReportService.download(url).then((r) => {
      if (r.data.body.data.size === 0) {
        snackbar.enqueueSnackbar(
          t('No data was found to download the report.'),
          {
            variant: 'warning',
          },
        );
        return;
      }
      const type = 'text/csv';
      const fileName = url.split('/').pop();
      saveAs(new Blob([r.data.body.data], { type }), `${fileName}.csv`);
      snackbar.enqueueSnackbar(`Arquivo ${fileName} baixado com sucesso.`, {
        variant: 'success',
        autoHideDuration: 3500,
      });
    });
  };

  const handleClickShowDialog = () => {
    if (!openDialogReport === false) {
      setSellerId('');
    }

    getSellerIdSuggestions();
    setOpenDialogReport(!openDialogReport);
  };

  const handleChangeSellerId = (event: React.ChangeEvent<{ value: any }>) => {
    setSellerId(event.target.value);
  };

  const handleChangeFrequency = (event: React.ChangeEvent<{ value: any }>) => {
    setFrequency(event.target.value);
  };

  const handleChangeWeekDay = (event: React.ChangeEvent<{ value: any }>) => {
    setWeekDay(event.target.value);
  };

  const getSellerIdSuggestions = () => {
    SuggestionsServices.getSellerIdsSuggestions().then((response) => {
      const suggestions = response.data.body;

      setSellerIds(suggestions);
    });
  };

  const handleSubmitReportCreate = () => {
    const initialDateTime =
      frequency === 'adhoc' ? selectedJPMorganInicialDate : jpReportInitialTime;
    const finalDateTime = selectedJPMorganFinalDate;

    if (!initialDateTime) {
      snackbar.enqueueSnackbar('Initial Date/Scheduled Time not informed.', {
        variant: 'error',
        autoHideDuration: 3500,
        key: '400',
      });
      return;
    }

    if (!finalDateTime && frequency === 'adhoc') {
      snackbar.enqueueSnackbar('Final Date not informed.', {
        variant: 'error',
        autoHideDuration: 3500,
        key: '400',
      });
      return;
    }

    setCreateLoading(true);

    JpMorganReportService.create({
      sellerId,
      frequency,
      initialDateTime,
      finalDateTime,
      weekDay,
    })
      .then(() => {
        snackbar.enqueueSnackbar('Report criado com sucesso.', {
          variant: 'success',
          autoHideDuration: 3500,
        });
        setOpenDialogReport(!openDialogReport);
        setLoading(true);
        setCreateLoading(false);
        fetchJpMorganReportData();
      })
      .catch((error) => {
        setCreateLoading(false);
        const { errors } = error.response.data;
        errors.forEach((err: any) => {
          snackbar.enqueueSnackbar(err.message, {
            variant: 'error',
            autoHideDuration: 3500,
            key: err.status,
          });
        });
      });
  };

  useEffect(() => {
    fetchJpMorganReportData();
  }, []);

  return (
    <>
      <Grid container className="mt-16">
        <Grid item xs={12}>
          <BoxShadow>
            {loading ? (
              <Loading />
            ) : (
              <TableEstorno
                rows={reports}
                columns={jpColumns}
                showBtn
                buttonTitle={t('JP Morgan Create Report')}
                title={t('JP Morgan Reports')}
                handleDownload={handleDownloadJpMorganReport}
                showPagination={false}
                handleClickShowDialog={handleClickShowDialog}
                t={t}
              />
            )}
          </BoxShadow>
        </Grid>
      </Grid>

      <Dialog
        open={openDialogReport}
        onClose={handleClickShowDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Enter JP Morgan Report Configuration Data')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item spacing={2} xs={12} lg={5} xl={5}>
              <FormControl fullWidth>
                <InputLabel id="select-seller-id" className="input-label">
                  {t('Seller Id')}
                </InputLabel>
                <Select
                  id="input-transaction-code-reversal"
                  className="input-transaction-code-reversal"
                  label={t('Seller Id')}
                  value={sellerId}
                  onChange={handleChangeSellerId}
                  fullWidth
                >
                  {sellerIds.map((item) => (
                    <MenuItem key={item.sellerId} value={item.sellerId}>
                      {item.sellerId.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item spacing={2} xs={12} lg={5} xl={5}>
              <FormControl fullWidth>
                <InputLabel id="select-frequency" className="input-label">
                  {t('Frequency')}
                </InputLabel>
                <Select
                  id="input-frequency-report"
                  className="input-frequency-report"
                  label={t('Frequency')}
                  value={frequency}
                  onChange={handleChangeFrequency}
                  fullWidth
                >
                  {frequencies.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {frequency === 'adhoc' ? (
              <>
                <Grid item spacing={2} xs={12} lg={5} xl={5}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-inicial"
                    label={t('Initial Date')}
                    format="MM/dd/yyyy"
                    value={selectedJPMorganInicialDate}
                    onChange={handleJPMorganDateChangeInicial}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item spacing={2} xs={12} lg={5} xl={5}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-final"
                    label={t('Final Date')}
                    format="MM/dd/yyyy"
                    value={selectedJPMorganFinalDate}
                    onChange={handleJPMorganDateChangeFinal}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item spacing={2} xs={12} lg={5} xl={5}>
                  <TimePicker
                    label="Scheduled Time"
                    value={jpReportInitialTime}
                    ampm={false}
                    onChange={(input) =>
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      setJpReportInitialTime(new Date(input!))}
                  />
                </Grid>
                {frequency === 'weekly' ? (
                  <Grid item spacing={2} xs={12} lg={5} xl={5}>
                    <FormControl fullWidth>
                      <InputLabel id="select-week-day" className="input-label">
                        {t('Weekly Start Day')}
                      </InputLabel>
                      <Select
                        id="input-report-week-day"
                        className="input-report-week-day"
                        label={t('Weekly Start Day')}
                        value={weekDay}
                        onChange={handleChangeWeekDay}
                        fullWidth
                      >
                        {weekDays.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={12} sm={3}>
              <Button
                text={t('Cancel')}
                className={
                  createLoading ? 'summary-confirm-disabled' : 'cancel'
                }
                type="button"
                onClick={handleClickShowDialog}
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ position: 'relative' }}>
              <Button
                text={t('Create')}
                className={
                  createLoading ? 'summary-confirm-disabled' : 'estorno'
                }
                type="button"
                onClick={handleSubmitReportCreate}
                disabled={createLoading}
              />
            </Grid>
          </Grid>
        </DialogActions>
        {createLoading && <LinearProgress />}
      </Dialog>
    </>
  );
};

export default JpMorganReport;
