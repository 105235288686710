import { AxiosResponse } from 'axios';
import api from '../api';
import ICheckoutPaymentDTO, {
  ICheckoutTicketPayment,
  ICheckoutCreditCardPayment,
  PaymentOrderDTO,
  WireTransferPaymentDTO,
  type PaymentCheckoutDTO,
} from './interfaces/ICheckoutPaymentDTO';

type PaymentResponse = Promise<AxiosResponse>;

const PaymentService = {
  baseUri: '/payment',

  sendToCreditPayment(
    paymentData: ICheckoutCreditCardPayment,
  ): PaymentResponse {
    return api.post(`${PaymentService.baseUri}/credit`, paymentData);
  },

  sendToDebitPayment(paymentData: ICheckoutPaymentDTO): PaymentResponse {
    return api.post(`${PaymentService.baseUri}/debit`, paymentData);
  },

  sendToTicketRegistration(
    paymentData: ICheckoutTicketPayment,
  ): Promise<AxiosResponse> {
    return api.post(`${PaymentService.baseUri}/ticket`, paymentData, {
      responseType: 'blob',
    });
  },

  createPaymentOrder(paymentOrderData: PaymentOrderDTO): PaymentResponse {
    return api.post(`${PaymentService.baseUri}/transfer`, paymentOrderData);
  },

  attachReceipt(wireTransferPaymentData: FormData): PaymentResponse {
    return api.post(
      `${PaymentService.baseUri}/transfer/finish`,
      wireTransferPaymentData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
  },

  sendWireTransferSendLaterMail(
    wireTransferPaymentData: WireTransferPaymentDTO,
  ): Promise<PaymentResponse> {
    return api.post(
      `${PaymentService.baseUri}/transfer/send-later-mail`,
      wireTransferPaymentData,
    );
  },

  sendToCheckoutPayment(paymentData: PaymentCheckoutDTO): PaymentResponse {
    return api.post(`${PaymentService.baseUri}/checkout`, paymentData);
  },
};

export default PaymentService;
