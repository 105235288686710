import React from 'react';
import { PdfViewerUi } from './styles';
import Loading from '../Loading';

export interface IPdfViewerProps {
  source: string | null;
}

const PdfViewer = ({ source }: IPdfViewerProps): JSX.Element => {
  return (
    <PdfViewerUi>
      {source ? (
        <iframe
          title="pdf-frame"
          src={source}
          frameBorder="0"
          className="pdf-view"
        />
      ) : (
        <Loading />
      )}
    </PdfViewerUi>
  );
};

export default PdfViewer;
