/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default (object1: any, object2: any): boolean => {
  if ((!object1 && !!object2) || (!!object1 && !object2)) return true;
  return Object.values(object1).some(
    (e) =>
      !Object.values(object2)
        .map((x) => `${x}`.trim())
        .includes(`${e}`.trim()),
  );
};
