import termsAndContitionsApi from './api/termsAndConditionsApi';
import { TermsAndConditionAcceptResponseDTO } from './dto/termsAndConditionAcceptResponseDTO';
import { TermsAndConditionsAcceptRequestDTO } from './dto/termsAndConditionsAcceptRequestDTO';

import { TermsAndConditionsResponse } from './dto/termsAndConditionsResponse';
import { TermsAndConditionTokenDTO } from './dto/termsAndConditionTokenDTO';
import { TermsAndContitionsRequestDTO } from './dto/termsAndContitionsRequestDTO';

const TermsAndConditionsService = {
  profilesToAccept: process.env.REACT_APP_TCS_PROFILE_TO_ACCEPT,

  async getAcceptanceOrContract(
    token: TermsAndConditionTokenDTO,
  ): Promise<TermsAndConditionsResponse | true> {
    if (!this.profilesToAccept) {
      throw new Error('Missing profiles to accept env var');
    }

    const profilesToAccept = this.profilesToAccept.split(/\W+\s*/);
    if (!profilesToAccept.some((a) => token.profile.includes(a))) return true;

    const url = '/verifyusersystem';

    const payload: TermsAndContitionsRequestDTO = {
      system: 'EPY',
      userEmail: token.email_user,
      userLogin: token.login_user,
      customers: token.customers.map((customer) => customer.code),
    };

    const {
      data,
    } = await termsAndContitionsApi.post<TermsAndConditionsResponse>(
      url,
      payload,
    );

    if (!data.accepted) {
      return data;
    }

    return true;
  },

  async acceptTermsAndConditions(
    payload: TermsAndConditionsAcceptRequestDTO,
  ): Promise<TermsAndConditionAcceptResponseDTO> {
    const url = `/accept`;

    const {
      data,
    } = await termsAndContitionsApi.post<TermsAndConditionAcceptResponseDTO>(
      url,
      payload,
    );

    return data;
  },
};

export default TermsAndConditionsService;
