import { AxiosResponse } from 'axios';
import api from '../api';

export type IReversalServiceSearch = {
  transactionNumber?: string;
  application?: string;
  salesOrganization?: string;
  page?: number;
  limit?: number;
};

export type ICreateReversal = {
  transactionNumber: string;
  typeReversal: string;
  value?: string;
};

const ReversalService = {
  search: ({
    transactionNumber = '',
    application = '',
    salesOrganization = '',
    page = 0,
    limit = 10,
  }: IReversalServiceSearch): Promise<AxiosResponse> => {
    const currPage = page + 1;
    let qs = '';
    if (transactionNumber) qs += `&transactionNumber=${transactionNumber}`;
    if (application) qs += `&application=${application}`;
    if (salesOrganization) qs += `&salesOrganization=${salesOrganization}`;
    if (typeof page === 'number') qs += `&page=${currPage}`;
    if (limit) qs += `&limit=${limit}`;
    if (qs) qs = `/?${qs.slice(1)}`;
    return api.get(`/reversal/search${qs}`);
  },

  createReversal: ({
    transactionNumber,
    value,
    typeReversal,
  }: ICreateReversal): Promise<AxiosResponse> => {
    return api.post('/reversal/create', {
      transactionNumber,
      value,
      typeReversal,
    });
  },
};

export default ReversalService;
