import { AxiosResponse } from 'axios';
import api from '../api';

import { JpMorganCreateReportRequestDTO } from './dto/jpMorganCreateReportRequestDTO';

const uri = '/report';

const JpMorganReportService = {
  list: async (): Promise<AxiosResponse> => {
    return api.get(`${uri}/jp-morgan-report`);
  },

  download: async (url: string): Promise<AxiosResponse> => {
    return api.get(`${uri}/download-jp-morgan-report/?url=${url}`);
  },

  create: async ({
    sellerId,
    frequency,
    initialDateTime,
    finalDateTime,
    weekDay,
  }: JpMorganCreateReportRequestDTO): Promise<AxiosResponse> => {
    return api.post(`${uri}/create-jp-morgan-report`, {
      sellerId,
      frequency,
      initialDateTime,
      finalDateTime,
      weekDay,
    });
  },
};

export default JpMorganReportService;
