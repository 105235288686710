import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const ContainerPage = styled.div`
  padding-top: 80px;
`;

export const TextInputTransactionCode = styled(TextField)`
  width: 100%;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
`;
