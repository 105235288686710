/* eslint-disable camelcase */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_pt_BR from '@amcharts/amcharts4/lang/pt_BR';
import * as am4core from '@amcharts/amcharts4/core';

import checkoutEnUs from './en-US/checkout.json';
import checkoutPtBr from './pt-BR/checkout.json';

import dashboardEnUs from './en-US/dashboard.json';
import dashboardPtBr from './pt-BR/dashboard.json';

import reversalEnUs from './en-US/reversal.json';
import reversalPtBr from './pt-BR/reversal.json';

import reportEnUs from './en-US/report.json';
import reportPtBr from './pt-BR/report.json';

import approvalsEnUs from './en-US/approvals.json';
import approvalsPtBr from './pt-BR/approvals.json';

import bankInformationEnUs from './en-US/bank-information.json';
import bankInformationPtBr from './pt-BR/bank-information.json';

import globalsEnUs from './en-US/globals.json';
import globalsPtBr from './pt-BR/globals.json';

const resources = {
  en: {
    checkout: checkoutEnUs,
    dashboard: dashboardEnUs,
    reversal: reversalEnUs,
    report: reportEnUs,
    approvals: approvalsEnUs,
    bankInformation: bankInformationEnUs,
    globals: globalsEnUs,
  },
  pt: {
    checkout: checkoutPtBr,
    dashboard: dashboardPtBr,
    reversal: reversalPtBr,
    report: reportPtBr,
    approvals: approvalsPtBr,
    bankInformation: bankInformationPtBr,
    globals: globalsPtBr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    detection: {
      order: ['cookie', 'navigator'],
      cache: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export const am4ChartsLang = (() => {
  let lang;
  if (/(pt-BR)/.test(navigator.language)) lang = am4lang_pt_BR;
  else lang = am4lang_en_US;

  am4core.options.defaultLocale = lang;
})();

export default i18n;
