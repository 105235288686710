import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Swal from 'sweetalert2';
import { DetailsCardUi } from './style';
import CheckoutService from '../../../services/checkout/CheckoutService';
import ICheckout from '../../../services/checkout/interfaces/ICheckoutDTO';
import BoxShadow from '../../BoxShadow';
import TableData from '../../Table';

export interface IDetailsTotal {
  id?: string;
  totalAmount?: string;
  totalInterestAmount?: string;
  totalIrfValue?: string;
  totalLiquidAmount?: string;
  paymentAmount?: string;
  cardBrands?: string[];
}

export interface IDetails {
  correlationId?: string;
  number?: string;
  issuedDate?: Date;
  dueDate?: Date;
  totalAmount?: string;
  liquidAmount?: string;
  irfType1?: string;
  irfType2?: string;
  irfType3?: string;
  irfType4?: string;
  irfType5?: string;
  irfType6?: string;
  irfType7?: string;
  irfType8?: string;
  irfType9?: string;
  irfType10?: string;
  irfValue1?: string;
  irfValue2?: string;
  irfValue3?: string;
  irfValue4?: string;
  irfValue5?: string;
  irfValue6?: string;
  irfValue7?: string;
  irfValue8?: string;
  irfValue9?: string;
  irfValue10?: string;
  withheldAmount?: string;
  titleIrf?: string;
  interestAmount: string;
  interestDays: number;
  installment?: string;
  salesOrganization?: string;
}

export interface IDetailsLines {
  documents?: IDetails[];
  detailsTotal?: IDetailsTotal;
  handleCheckoutUpdate: (updatedCheckout: ICheckout) => void;
  gateway: string;
}

interface IColumn {
  id: string;
  label: string;
  minWidth?: string | undefined;
  maxWidth?: string | undefined;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}

const Details = ({
  documents = [],
  detailsTotal = {},
  handleCheckoutUpdate,
  gateway,
}: IDetailsLines): JSX.Element => {
  const { t } = useTranslation('checkout');

  const [showIrf, setShowIrf] = useState(false);
  const [showInterest, setShowInterest] = useState(false);
  const [detailsTotalState, setDetailsTotal] = useState(detailsTotal);
  const [pageState, setPageState] = useState(0);
  const [totalPerPageState, setTotalPerPageState] = useState(10);
  const columns: IColumn[] = [
    { id: 'number', label: t('Doc #'), align: 'left' },
    { id: 'issuedDate', label: t('Created At'), align: 'left' },
    { id: 'dueDate', label: t('Due Date'), align: 'left' },
    { id: 'totalAmount', label: t('Total'), align: 'left' },
  ];

  const { goBack } = useHistory();

  const handleColumns = (tableColumns: IColumn[]) => {
    const isBot1 = documents.filter((doc) => doc.salesOrganization === 'BOT1');

    if (gateway === 'GetnetGateway' && isBot1.length === 0) {
      tableColumns.splice(0, 0, {
        id: 'selections',
        label: 'Selections',
        align: 'left',
      });
      tableColumns.splice(4, 0, {
        id: 'installment',
        label: t('Installment'),
        align: 'center',
      });
    }

    if (showInterest) {
      tableColumns.push({
        id: 'interestAmount',
        label: t('Interest'),
        align: 'left',
      });
      tableColumns.push({
        id: 'interestDays',
        label: t('Days Overdue'),
        align: 'left',
      });
    }

    if (showIrf) {
      tableColumns.push({
        id: 'withheldAmount',
        label: t('Withheld Amount'),
        align: 'left',
      });
      tableColumns.push({
        id: 'liquidAmount',
        label: t('Liquid Amount'),
        align: 'left',
      });
      tableColumns.push({ id: 'taxes', label: t('Taxes'), align: 'left' });
    }

    return tableColumns;
  };

  const grandTotalLine = (totals: IDetailsTotal) => {
    return (
      <div className="row">
        <div className="column">Grand Total</div>
        <div className="column">{totals.paymentAmount}</div>
      </div>
    );
  };

  const handleDelete = useCallback(
    async (documentIds: string[]) => {
      const updatedCheckout = await CheckoutService.deleteDocumentFromCheckout(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        detailsTotalState.id!,
        documentIds,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        detailsTotalState.cardBrands!,
      );

      setDetailsTotal(updatedCheckout.data.body);

      handleCheckoutUpdate(updatedCheckout.data.body);
    },
    [detailsTotalState.cardBrands, detailsTotalState.id, handleCheckoutUpdate],
  );

  useEffect(() => {
    documents.forEach((doc) => {
      if (
        doc.irfType1 ||
        doc.irfType2 ||
        doc.irfType3 ||
        doc.irfType4 ||
        doc.irfType5 ||
        doc.irfType6 ||
        doc.irfType7 ||
        doc.irfType8 ||
        doc.irfType9 ||
        doc.irfType10
      )
        setShowIrf(true);

      if (doc.interestDays || doc.interestAmount !== '$0.00')
        setShowInterest(true);
    });
  }, [documents]);

  const getRowsPerPage = (rowsPerPage: number) => {
    setPageState(0);
    setTotalPerPageState(rowsPerPage);
  };

  const handlePageChange = (page: number) => {
    setPageState(page);
  };

  const handleShowDocuments = (): IDetails[] => {
    if (documents.length === 0) {
      Swal.fire({
        title: 'Alerta!',
        html: `Todas as faturas foram removidas, o checkout será cancelado.`,
        icon: 'warning',
        allowOutsideClick: true,
        allowEscapeKey: true,
      }).then(() => {
        goBack();
      });
    }

    const pageOffset = pageState * totalPerPageState;
    const paginatedDocuments = documents
      .sort((row) => {
        if (Number(row.installment) > 1) return -1;
        return 1;
      })
      .filter((document, index) => {
        return index >= pageOffset && index < pageOffset + totalPerPageState;
      });
    return paginatedDocuments;
  };

  return (
    <DetailsCardUi
      totalLine={documents?.length > 1 && (showIrf || showInterest)}
    >
      <Grid container>
        <Grid item xs={12}>
          <div className="title">
            <h5>{t('Details')}</h5>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BoxShadow>
          <TableData
            rows={handleShowDocuments()}
            columns={handleColumns(columns)}
            showBtn={false}
            totalRows={documents.length}
            handleRowsPerPageChange={getRowsPerPage}
            page={pageState}
            handlePageChange={handlePageChange}
            rowsPerPage={totalPerPageState}
            totalInterestAmount={detailsTotalState.totalInterestAmount}
            totalIrfValue={showIrf ? detailsTotalState.totalIrfValue : ''}
            totalLiquidAmount={
              showIrf ? detailsTotalState.totalLiquidAmount : ''
            }
            showCheckbox
            showPagination
            handleDelete={handleDelete}
          />
        </BoxShadow>
      </Grid>
      {documents?.length > 1 ? grandTotalLine(detailsTotalState) : null}
    </DetailsCardUi>
  );
};

export default Details;
