import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { MdDelete } from 'react-icons/md';
import Button from '../Button';
import bankingService from '../../services/banking/banking-service';
import { BankInformationDTO } from '../../services/banking/dtos/bank-info-dto';
import {
  CompanyBankInformation,
  AccountsType,
} from '../../services/banking/interfaces/i-company-banking-information';

interface DialogBankInformationType {
  openDialog: boolean;
  handleCloseDialog: () => void;
  bankingInformation: BankInformationDTO;
  isEdit: boolean;
  setOpenDialog: (dialogState: boolean) => void;
  setListBankInformation: (bankInfo: CompanyBankInformation[]) => void;
  setTotalRowsList: (total: number) => void;
  setIsEdit: (isEdit: boolean) => void;
}

interface AccountsErrorsType {
  nameError: boolean;
  accountError: boolean;
}

export default ({
  openDialog,
  handleCloseDialog,
  bankingInformation,
  isEdit,
  setOpenDialog,
  setListBankInformation,
  setTotalRowsList,
  setIsEdit,
}: DialogBankInformationType): JSX.Element => {
  const { t } = useTranslation('bankInformation');
  const snackbar = useSnackbar();

  const [bankName, setBankName] = useState('');
  const [bankNameError, setBankNameError] = useState(false);
  const [salesOrganization, setSalesOrganization] = useState('');
  const [salesOrganizationError, setSalesOrganizationError] = useState(false);
  const [currency, setCurrency] = useState('');
  const [currencyError, setCurrencyError] = useState(false);
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [beneficiaryNameError, setBeneficiaryNameError] = useState(false);
  const [bankAddress, setBankAddress] = useState('');
  const [account, setAccount] = useState('');
  const [agency, setAgency] = useState('');
  const [swift, setSwift] = useState('');
  const [aba, setAba] = useState('');
  const [beneficiaryAddress, setBeneficiaryAddress] = useState('');
  const [beneficiaryAddressError, setBeneficiaryAddressError] = useState(false);
  const [accounts, setAccounts] = useState<AccountsType[] | []>([]);
  const [accountsErrors, setAccountsErrors] = useState<
    AccountsErrorsType[] | []
  >([]);

  const addInputs = () => {
    if (accounts.length < 5) {
      const newAccount = { name: '', account: '' };
      const newAccountsErrors = { nameError: false, accountError: false };

      setAccounts([...accounts, newAccount]);
      setAccountsErrors([...accountsErrors, newAccountsErrors]);
    }
  };

  const removeInputs = (index: number) => {
    accounts.splice(index, 1);

    setAccounts([...accounts]);
  };

  const handleSetAccountName = (name: string, index: number) => {
    const newName = { ...accounts[index], name: name || '' };

    accounts[index] = newName;
    accountsErrors[index] = { ...accountsErrors[index], nameError: false };

    setAccounts([...accounts]);
    setAccountsErrors([...accountsErrors]);
  };

  const handleSetAccount = (acc: string, index: number) => {
    const newAccount = { ...accounts[index], account: acc || '' };

    accounts[index] = newAccount;
    accountsErrors[index] = { ...accountsErrors[index], accountError: false };

    setAccounts([...accounts]);
    setAccountsErrors([...accountsErrors]);
  };

  const handleSave = async () => {
    const errors = [];

    if (!bankName) {
      snackbar.enqueueSnackbar(t('Bank Name not filled'), {
        variant: 'warning',
      });
      setBankNameError(true);
      errors.push('bank-name-error');
    }

    if (!salesOrganization) {
      snackbar.enqueueSnackbar(t('Site not filled'), {
        variant: 'warning',
      });
      setSalesOrganizationError(true);
      errors.push('site-error');
    }

    if (!currency) {
      snackbar.enqueueSnackbar(t('Currency not filled'), {
        variant: 'warning',
      });
      setCurrencyError(true);
      errors.push('currency-error');
    }

    if (!beneficiaryName) {
      snackbar.enqueueSnackbar(t('Bank Beneficiary Name not filled'), {
        variant: 'warning',
      });
      setBeneficiaryNameError(true);
      errors.push('beneficiary-name-error');
    }

    if (!beneficiaryAddress) {
      snackbar.enqueueSnackbar(t('Bank Beneficiary Address not filled'), {
        variant: 'warning',
      });
      setBeneficiaryAddressError(true);
      errors.push('beneficiary-address-error');
    }

    if (accounts.length > 0) {
      const accountsErrorMessages: string[] = [];
      accounts.forEach((acc, index) => {
        if (!acc.name) {
          accountsErrors[index] = { ...accountsErrors[index], nameError: true };
          setAccountsErrors([...accountsErrors]);
          accountsErrorMessages.push(`accounts-error-name`);
        }
        if (!acc.account) {
          accountsErrors[index] = {
            ...accountsErrors[index],
            accountError: true,
          };

          setAccounts([...accounts]);
          setAccountsErrors([...accountsErrors]);
          accountsErrorMessages.push(`accounts-error-account`);
        }
      });

      if (accountsErrorMessages.length > 0) {
        snackbar.enqueueSnackbar(t('Accounts not filled'), {
          variant: 'warning',
        });
        errors.push('accounts-error');
      }
    }

    if (errors.length > 0) return;

    try {
      const bankInfo: BankInformationDTO = {
        accounts,
        bankName,
        salesOrganization,
        currency,
        beneficiaryName,
        account,
        agency,
        aba,
        beneficiaryAddressOne: beneficiaryAddress,
        bankAddress,
        swift,
      };

      if (isEdit) {
        await bankingService.update({ ...bankInfo, id: bankingInformation.id });
        snackbar.enqueueSnackbar(t('Edit success'), {
          variant: 'success',
        });
      } else {
        await bankingService.create(bankInfo);
        snackbar.enqueueSnackbar(t('Save success'), {
          variant: 'success',
        });
        clearInputs();
      }

      await bankingService.findAll({}).then((response) => {
        setListBankInformation(response.data.body.bankInformation);
        setTotalRowsList(response.data.body.total);
      });
    } catch (error) {
      snackbar.enqueueSnackbar(
        `${isEdit ? t('Edit error message') : t('Save error message')}`,
        {
          variant: 'error',
        },
      );
    }

    setOpenDialog(!openDialog);
  };

  const clearInputs = useCallback(() => {
    setBankName('');
    setBankNameError(false);
    setSalesOrganization('');
    setSalesOrganizationError(false);
    setCurrency('');
    setCurrencyError(false);
    setBeneficiaryName('');
    setBeneficiaryNameError(false);
    setBankAddress('');
    setBeneficiaryAddress('');
    setBeneficiaryAddressError(false);
    setAccount('');
    setAgency('');
    setAba('');
    setSwift('');
    setAccounts([]);
    setAccountsErrors([]);
    setIsEdit(false);
  }, [setIsEdit]);

  useEffect(() => {
    if (isEdit) {
      setBankName(bankingInformation.bankName || '');
      setSalesOrganization(bankingInformation.salesOrganization || '');
      setCurrency(bankingInformation.currency || '');
      setBeneficiaryName(bankingInformation.beneficiaryName || '');
      setBankAddress(bankingInformation.bankAddress || '');
      setBeneficiaryAddress(bankingInformation.beneficiaryAddressOne || '');
      setAccount(bankingInformation.account || '');
      setAgency(bankingInformation.agency || '');
      setAba(bankingInformation.aba || '');
      setSwift(bankingInformation.swift || '');
      setAccounts(bankingInformation.accounts || []);

      const accountErrorsEdit: AccountsErrorsType[] = [];
      if (bankingInformation.accounts) {
        bankingInformation.accounts.forEach(() => {
          accountErrorsEdit.push({ nameError: false, accountError: false });
        });
      }
      setAccountsErrors(accountErrorsEdit);

      return;
    }

    clearInputs();
  }, [bankingInformation, clearInputs, isEdit]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {t('New Bank Information Dialog Message')}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          className="align-items-center"
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <TextField
              placeholder="BANCO DO BRASIL"
              name="bank-name"
              label={t('Bank Name')}
              value={bankName}
              onChange={(input) => {
                setBankName(input.target.value);
                setBankNameError(false);
              }}
              className="input-admin-custom"
              error={bankNameError}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              placeholder="SJK1"
              value={salesOrganization}
              onChange={(input) => {
                setSalesOrganization(input.target.value);
                setSalesOrganizationError(false);
              }}
              label={t('Site')}
              name="site"
              error={salesOrganizationError}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              placeholder="BRL"
              value={currency}
              onChange={(input) => {
                setCurrency(input.target.value);
                setCurrencyError(false);
              }}
              label={t('Currency')}
              name="currency"
              error={currencyError}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              placeholder="21 25 RUE BALZAC, PARIS, FRANCE"
              name="bank-address"
              label={t('Bank Address')}
              value={bankAddress}
              onChange={(input) => setBankAddress(input.target.value)}
              className="input-admin-custom"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              placeholder="EMBRAER S.A."
              value={beneficiaryName}
              onChange={(input) => {
                setBeneficiaryName(input.target.value);
                setBeneficiaryNameError(false);
              }}
              label={t('Bank Beneficiary Name')}
              name="beneficiaryName"
              error={beneficiaryNameError}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              placeholder="AVENIDA BRIGADEIRO FARIA LIMA,2170 - SJCAMPOS/SP"
              value={beneficiaryAddress}
              onChange={(input) => {
                setBeneficiaryAddress(input.target.value);
                setBeneficiaryAddressError(false);
              }}
              label={t('Bank Beneficiary Address')}
              name="beneficiaryAddress"
              error={beneficiaryAddressError}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              placeholder="140.000-2/3400-2"
              fullWidth
              label={t('Account')}
              name="account"
              value={account}
              onChange={(input) => setAccount(input.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              placeholder="700"
              fullWidth
              label={t('Agency')}
              name="agency"
              value={agency}
              onChange={(input) => setAgency(input.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              placeholder="021000089"
              fullWidth
              label={t('Aba')}
              name="aba"
              value={aba}
              onChange={(input) => setAba(input.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              placeholder="CITIUS33"
              fullWidth
              label={t('Swift')}
              name="swift"
              value={swift}
              onChange={(input) => setSwift(input.target.value)}
            />
          </Grid>
          <Grid
            container
            alignItems="center"
            className="align-items-center"
            spacing={2}
            style={{ paddingTop: '16px' }}
          >
            <Grid item xs={6} md={6}>
              <h3>{t('Ledger Account Information')}</h3>
            </Grid>
            <Grid item xs={6} md={6} justify-content="flex-end">
              <Button
                type="button"
                className="summary-confirm-active"
                text={t('Add')}
                onClick={addInputs}
                disabled={accounts.length === 5}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: '16px', paddingBottom: '16px' }}
          >
            <Divider
              style={{
                color: 'red',
              }}
            />
          </Grid>
        </Grid>
        {accounts
          ? accounts.map((a: AccountsType, index: number) => (
              // eslint-disable-next-line react/jsx-indent
              <Grid
                container
                alignItems="center"
                className="align-items-center"
                spacing={2}
                key={`accounts-${index * 1}`}
              >
                <Grid item xs={6} md={6}>
                  <TextField
                    key={`accountName-${index * 1}`}
                    fullWidth
                    label={t('Name')}
                    name="accountName"
                    value={a.name}
                    onChange={(input) =>
                      handleSetAccountName(input.target.value, index)
                    }
                    error={accountsErrors[index].nameError}
                  />
                </Grid>
                <Grid item xs={5} md={5}>
                  <TextField
                    key={`accountNumber-${index * 1}`}
                    fullWidth
                    label={t('Account')}
                    name="account"
                    value={a.account}
                    onChange={(input) =>
                      handleSetAccount(input.target.value, index)
                    }
                    error={accountsErrors[index].accountError}
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <IconButton onClick={() => removeInputs(index)}>
                    <MdDelete color="red" />
                  </IconButton>
                </Grid>
                <Grid />
              </Grid>
            ))
          : null}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" style={{ padding: '16px' }}>
          <Grid item xs={12} sm={4} style={{ marginRight: '16px' }}>
            <Button
              onClick={() => {
                handleCloseDialog();
                clearInputs();
              }}
              text={t('Cancel')}
              className="cancel"
              type="button"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              text={t('Save')}
              className="summary-confirm-active"
              type="button"
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
