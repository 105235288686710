import React, { useState, useEffect } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

import {
  RiDashboardFill,
  RiArrowGoBackFill,
  RiArrowDropUpLine,
  RiArrowDropDownLine,
  RiArrowDropRightFill,
} from 'react-icons/ri';
import { SiMicrosoftexcel } from 'react-icons/si';
import { MdSecurity, MdApproval } from 'react-icons/md';

import { NavUiLeft } from './style';
import logoMyTechcare from '../../../assets/images/logo_myTechCare-header.png';
import { useAuth } from '../../../hooks/auth';
import { IUserAuthenticatedProps } from '../../../hooks/interfaces/index';
import checkProfile, {
  BASIC,
  ADMIN,
  SUPPORT,
} from '../../../helpers/checkProfile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
  }),
);

interface IPropsSidebar {
  toolbar?: string;
}

const SidebarLeft = ({ toolbar }: IPropsSidebar): JSX.Element => {
  const routes = useRouteMatch();
  const classes = useStyles();
  const { user } = useAuth();

  const [menuView, setMenuView] = useState('');
  const [showSubAdmins, setShowSubAdmins] = useState(false);
  const [userState, setUserState] = useState({} as IUserAuthenticatedProps);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    routes.url === '/admin/payment-currency' ||
    routes.url === '/admin/webhook' ||
    routes.url === '/admin/sites' ||
    routes.url === '/admin/gateways' ||
    routes.url === '/admin/bank-information'
      ? setShowSubAdmins(true)
      : setShowSubAdmins(false);

    setMenuView(routes.url);
  }, [routes]);

  useEffect(() => {
    if (user && user.login !== undefined) setUserState(user);
  }, [user]);

  return (
    <NavUiLeft>
      <div className={toolbar}>
        <Link
          to="/admin"
          style={{
            backgroundColor: '#F1F3F4',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <img src={logoMyTechcare} alt="Embraer" />
          {/* <img src={logoFlyEmbraer} alt="Embraer" /> */}
        </Link>
      </div>
      <Divider />
      <List component="nav" aria-labelledby="nested-list-subheader">
        {userState.profile !== undefined &&
        checkProfile({
          profileAccept: BASIC,
          profile: userState.profile,
        }) ? (
          <Link
            to="/admin"
            className={
              menuView === '/admin' || menuView === '/' ? 'active' : ''
            }
          >
            <ListItem button>
              <ListItemIcon>
                <RiDashboardFill size="20" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
        ) : null}
        {userState.profile !== undefined &&
        checkProfile({
          profileAccept: ADMIN,
          profile: userState.profile,
        }) ? (
          <Link
            to="/admin/reversal"
            className={menuView === '/admin/reversal' ? 'active' : ''}
          >
            <ListItem button>
              <ListItemIcon>
                <RiArrowGoBackFill size="20" />
              </ListItemIcon>
              <ListItemText primary="Estorno" />
            </ListItem>
          </Link>
        ) : null}
        {userState.profile !== undefined &&
        checkProfile({
          profileAccept: BASIC,
          profile: userState.profile,
        }) ? (
          <Link
            to="/admin/report"
            className={menuView === '/admin/report' ? 'active' : ''}
          >
            <ListItem button>
              <ListItemIcon>
                <SiMicrosoftexcel size="20" />
              </ListItemIcon>
              <ListItemText primary="Relatório" />
            </ListItem>
          </Link>
        ) : null}
        {userState.profile !== undefined &&
        checkProfile({
          profileAccept: BASIC,
          profile: userState.profile,
        }) ? (
          <Link
            to="/admin/approvals"
            className={menuView === '/admin/approvals' ? 'active' : ''}
          >
            <ListItem button>
              <ListItemIcon>
                <MdApproval size="20" />
              </ListItemIcon>
              <ListItemText primary="Aprovações" />
            </ListItem>
          </Link>
        ) : null}
        {userState.profile !== undefined &&
        checkProfile({
          profileAccept: ADMIN,
          profile: userState.profile,
        }) ? (
          <>
            <ListItem button onClick={() => setShowSubAdmins(!showSubAdmins)}>
              <ListItemIcon>
                <MdSecurity size="20" />
              </ListItemIcon>
              <ListItemText primary="Administrador" />
              {showSubAdmins ? (
                <RiArrowDropUpLine size="32" />
              ) : (
                <RiArrowDropDownLine size="32" />
              )}
            </ListItem>
            <Collapse in={showSubAdmins} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {userState.profile !== undefined &&
                checkProfile({
                  profileAccept: SUPPORT,
                  profile: userState.profile,
                }) ? (
                  <Link
                    to="/admin/gateways"
                    className={menuView === '/admin/gateways' ? 'active' : ''}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <RiArrowDropRightFill size="32" />
                      </ListItemIcon>
                      <ListItemText primary="Gateways" />
                    </ListItem>
                  </Link>
                ) : null}
                {userState.profile !== undefined &&
                checkProfile({
                  profileAccept: SUPPORT,
                  profile: userState.profile,
                }) ? (
                  <Link
                    to="/admin/sites"
                    className={menuView === '/admin/sites' ? 'active' : ''}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <RiArrowDropRightFill size="32" />
                      </ListItemIcon>
                      <ListItemText primary="Sites" />
                    </ListItem>
                  </Link>
                ) : null}
                {userState.profile !== undefined &&
                checkProfile({
                  profileAccept: ADMIN,
                  profile: userState.profile,
                }) ? (
                  <Link
                    to="/admin/payment-currency"
                    className={
                      menuView === '/admin/payment-currency' ? 'active' : ''
                    }
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <RiArrowDropRightFill size="32" />
                      </ListItemIcon>
                      <ListItemText primary="Payment Currency" />
                    </ListItem>
                  </Link>
                ) : null}
                {userState.profile !== undefined &&
                checkProfile({
                  profileAccept: SUPPORT,
                  profile: userState.profile,
                }) ? (
                  <Link
                    to="/admin/webhook"
                    className={menuView === '/admin/webhook' ? 'active' : ''}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <RiArrowDropRightFill size="32" />
                      </ListItemIcon>
                      <ListItemText primary="Webhook" />
                    </ListItem>
                  </Link>
                ) : null}
                {userState.profile !== undefined &&
                checkProfile({
                  profileAccept: SUPPORT,
                  profile: userState.profile,
                }) ? (
                  <Link
                    to="/admin/bank-information"
                    className={
                      menuView === '/admin/bank-information' ? 'active' : ''
                    }
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <RiArrowDropRightFill size="32" />
                      </ListItemIcon>
                      <ListItemText primary="Bank Information" />
                    </ListItem>
                  </Link>
                ) : null}
              </List>
            </Collapse>
          </>
        ) : null}
      </List>
    </NavUiLeft>
  );
};

export default SidebarLeft;
