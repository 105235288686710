import React from 'react';

import logoAuthorize from '../../assets/images/logo-authorize-t.png';
import logoFivServ from '../../assets/images/fivserv.png';
import logoCielo from '../../assets/images/cielo.svg';
import logoGetNet from '../../assets/images/getnet-transparent.png';
import logoEmbraer from '../../assets/images/embraer.webp';
import logoJPMC from '../../assets/images/logo-jpmc.svg';

import { LogoUi } from './style';

type ILogosGateway = {
  gateway?: string;
};

const LogosGateway = ({ gateway }: ILogosGateway): JSX.Element => {
  if (/^Authorize/i.test(gateway || ''))
    return (
      <LogoUi>
        <img src={logoAuthorize} alt="Authorize.net" width="70" />
      </LogoUi>
    );

  if (/^Cielo/i.test(gateway || ''))
    return (
      <LogoUi>
        <img src={logoCielo} alt="Cielo" width="85" />
      </LogoUi>
    );

  if (/^Getnet/i.test(gateway || ''))
    return (
      <LogoUi>
        <img src={logoGetNet} alt="GetNet" width="100" />
      </LogoUi>
    );

  if (/^FivServ/i.test(gateway || ''))
    return (
      <LogoUi>
        <img src={logoFivServ} alt="GetNet" width="100" />
      </LogoUi>
    );

  if (/^JPMorgan/i.test(gateway || ''))
    return (
      <LogoUi>
        <img src={logoJPMC} alt="JPMorgan" width="100" />
      </LogoUi>
    );
  return (
    <LogoUi>
      <img src={logoEmbraer} alt="Embraer" width="150" />
    </LogoUi>
  );
};

export default LogosGateway;
