import { AxiosResponse } from 'axios';
import api from '../api';

const SuggestionsServices = {
  getApplicationsSuggestions: (): Promise<AxiosResponse> => {
    return api.get('/suggestions/report');
  },

  getApplicationsSuggestionsApprovals: (): Promise<AxiosResponse> => {
    return api.get('/suggestions/approvals');
  },

  getSuggestionsReversal: (): Promise<AxiosResponse> => {
    return api.get('/suggestions/reversal');
  },

  getSellerIdsSuggestions: (): Promise<AxiosResponse> => {
    return api.get('/suggestions/seller-ids');
  },
};

export default SuggestionsServices;
