/* eslint-disable import/extensions */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import GraphTotalRecebidos from '../../../../components/Admin/GraphTotalRecebidos';
import GraphTotalAplicacao from '../../../../components/Admin/GraphPorAplicacao';
import GraphBySalesOrganization from '../../../../components/Admin/GraphBySalesOrganization';
import GraphPorStatus from '../../../../components/Admin/GraphPorStatus';

import { HomeUi } from './style';

const Admin = (): JSX.Element => {
  const { t } = useTranslation('dashboard');

  return (
    <HomeUi>
      <Grid container>
        <Grid item xs={12}>
          <GraphTotalRecebidos
            title={t('Total Received')}
            width="100%"
            height="60vh"
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} xl={6}>
          <GraphTotalAplicacao
            title={t('By Application')}
            width="100%"
            height="60vh"
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={6}>
          <GraphBySalesOrganization
            title={t('By Sales Organization')}
            width="100%"
            height="60vh"
          />
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={12}>
          <GraphPorStatus title={t('By Status')} width="100%" height="60vh" />
        </Grid>
      </Grid>
    </HomeUi>
  );
};

export default Admin;
