import React from 'react';
import { NoDataUi } from './style';

type INoDataProps = {
  text: string;
};

export default ({ text }: INoDataProps): JSX.Element => {
  return (
    <NoDataUi>
      <h2>{text}</h2>
    </NoDataUi>
  );
};
