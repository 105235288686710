import styled from 'styled-components';

export const CardItemUi = styled.div<{ active?: boolean }>`
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  border-left: 5px solid ${(props) => (props.active ? '#1473E6' : '#fff')};
  margin-top: 10px;
  cursor: pointer;

  .card-brand {
    border-radius: 50%;
    background-color: #f5f5f5;
    height: 50px;
    width: 50px;
    position: relative;

    &__name {
      text-transform: capitalize;
      margin-left: 30px !important;
    }

    img,
    svg {
      max-width: 40px;
      max-height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
    }
  }

  .new-card {
    padding: 0 0 0 55px;
    display: flex;
    align-items: center;

    p {
      text-transform: capitalize;
      color: #6e6e6e;
      font-size: 13px;
      font-weight: 400;
    }
  }

  label.MuiFormControlLabel-root {
    display: flex;
    align-items: center;
  }

  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    & > span {
      margin-left: 15px;
      color: #6e6e6e;
      vertical-align: middle;
    }
  }
`;
