import React from 'react';

import { AdministratorContainer } from './style';
import PaymentCurrencyForm from '../../../../components/Forms/PaymentCurrency';

const PaymentCurrency = (): JSX.Element => {
  return (
    <AdministratorContainer>
      <PaymentCurrencyForm />
    </AdministratorContainer>
  );
};

export default PaymentCurrency;
