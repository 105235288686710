import styled from 'styled-components';

export const NoDataUi = styled.div`
  width: 100%;
  text-align: center;
  max-height: 50vh;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 20px;
    color: #c5c5c5;
    font-weight: normal;
  }
`;
