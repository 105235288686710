import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { HeaderUi } from './style';

import logoEpayments from '../../../assets/images/logo-epayments.svg';
import logoEmbraer from '../../../assets/images/logo-embraer-crop.png';

const Header = (): JSX.Element => {
  return (
    <HeaderUi>
      <Container maxWidth="xl">
        <Grid
          container
          className="container"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid className="logo-epayments" item xs={5} sm={3} md={2}>
            <img src={logoEpayments} alt="ePayments Embraer" />
          </Grid>
          <Grid className="logo-embraer" item xs={6} sm={4} md={3}>
            <img src={logoEmbraer} alt="ePayments Embraer" />
          </Grid>
        </Grid>
      </Container>
    </HeaderUi>
  );
};

export default Header;
