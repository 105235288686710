import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Redirect from '../components/Redirect';
import routesAdmin from './Admin/routes';
import Checkout from './Checkout/index';
import { PaymentProvider } from '../hooks/payment';
import { TCSProvider } from '../hooks/tcs';
import TermsAndConditions from './TermsAndConditions';

const Routes = (): JSX.Element => (
  <TCSProvider>
    <PaymentProvider>
      <Switch>
        <Route path="/" exact component={routesAdmin} />
        <Route path="/admin" exact component={routesAdmin} />
        <Route path="/admin/*" component={routesAdmin} />
        <Route path="/sso/*" component={routesAdmin} />

        <Route path="/checkout/:id" component={Checkout} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />

        <Route path="/redirect" component={Redirect} />
        <Route path="*" component={Redirect} />
      </Switch>
    </PaymentProvider>
  </TCSProvider>
);

export default Routes;
