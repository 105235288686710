import { AxiosResponse } from 'axios';
import api from '../api';

const uri = '/approvals/checkout';

export type ICheckoutApprovalsProps = {
  id: string;
  bankAccount: string;
  bankAccountFee: string;
  bankInformation: string;
  bankAt?: Date | string | null;
  notClearing?: boolean;
  creditDocument?: string;
};

export type ICheckoutDeclineProps = {
  id: string;
};

export type ICheckoutApprovalsVoucherProps = {
  filename: string;
};

export type ICheckoutApprovalsSearchProps = {
  application?: string;
  transactionNumber?: string;
  status?: string;
  salesOrganization?: string;
  initialCreation?: string;
  finalCreation?: string;
  page?: number;
  limit?: number;
  customer?: string;
  document?: string;
  transactionId?: string;
};

const CheckoutApprovalsService = {
  search: async ({
    application = '',
    transactionNumber = '',
    status = '',
    salesOrganization = '',
    initialCreation = '',
    finalCreation = '',
    page = 0,
    limit = 10,
    customer = '',
    document = '',
  }: ICheckoutApprovalsSearchProps): Promise<AxiosResponse> => {
    const currPage = page + 1;
    let qs = '';
    if (application) qs += `&application=${application}`;
    if (transactionNumber) qs += `&transactionNumber=${transactionNumber}`;
    if (salesOrganization) qs += `&salesOrganization=${salesOrganization}`;
    if (initialCreation) qs += `&initialCreation=${initialCreation}`;
    if (finalCreation) qs += `&finalCreation=${finalCreation}`;
    if (status) qs += `&status=${status}`;
    if (typeof page === 'number') qs += `&page=${currPage}`;
    if (limit) qs += `&limit=${limit}`;
    if (customer) qs += `&customer=${customer}`;
    if (document) qs += `&document=${document}`;
    if (qs) qs = `/?${qs.slice(1)}`;
    return api.get(`${uri}${qs}`);
  },

  download: async ({
    application = '',
    transactionNumber = '',
    status = '',
    salesOrganization = '',
    initialCreation = '',
    finalCreation = '',
    customer = '',
    document = '',
  }: ICheckoutApprovalsSearchProps): Promise<AxiosResponse> => {
    let qs = '';
    if (application) qs += `&application=${application}`;
    if (transactionNumber) qs += `&transactionNumber=${transactionNumber}`;
    if (salesOrganization) qs += `&salesOrganization=${salesOrganization}`;
    if (initialCreation) qs += `&initialCreation=${initialCreation}`;
    if (finalCreation) qs += `&finalCreation=${finalCreation}`;
    if (status) qs += `&status=${status}`;
    if (customer) qs += `&customer=${customer}`;
    if (document) qs += `&document=${document}`;
    if (qs) qs = `/?${qs.slice(1)}`;
    return api.get(`${uri}/download${qs}`, { responseType: 'blob' });
  },

  showVoucher: async ({
    filename = '',
  }: ICheckoutApprovalsVoucherProps): Promise<AxiosResponse> => {
    let qs = '';
    if (filename) qs += `&filename=${filename}`;
    if (qs) qs = `/?${qs.slice(1)}`;
    return api.get(`${uri}/voucher${qs}`);
  },

  approvalCheckout(data: ICheckoutApprovalsProps): Promise<AxiosResponse> {
    return api.post(`${uri}/approve`, data);
  },

  rejectCheckout: async ({
    id = '',
  }: ICheckoutDeclineProps): Promise<AxiosResponse> => {
    let qs = '';
    if (id) qs += `&id=${id}`;
    if (qs) qs = `/?${qs.slice(1)}`;
    return api.get(`${uri}/reject${qs}`);
  },
};

export default CheckoutApprovalsService;
