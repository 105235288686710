import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ButtonMaterialUi from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import TextField from '@material-ui/core/TextField';

import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '../../Button';
import BoxShadow from '../../BoxShadow';
import TableData from '../../Table';
import MenuButton from '../../MenuButton';
import paymentCurrency, {
  IPaymentCurrency,
} from '../../../services/paymentCurrency';
import Loading from '../../Loading';

interface IColumn {
  id: string;
  label: string;
  minWidth?: string | undefined;
  maxWidth?: string | undefined;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}

const PaymentCurrency = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const [loadingState, setLoadingState] = useState(true);
  const [listPaymentCurrency, setListPaymentCurrency] = useState(
    [] as IPaymentCurrency[],
  );
  const [totalRegister, setTotalRegister] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [totalPerPageState, setTotalPerPageState] = useState(10);
  const [showDialogPaymentCurrency, setShowDialogPaymentCurrency] = useState(
    false,
  );
  const [typeAction, setTypeAction] = useState('');
  const [salesOrganizationState, setSalesOrganizationState] = useState('');
  const [currencyState, setCurrency] = useState('');
  const [currencyDefaultState, setCurrencyDefaultState] = useState('');
  const [paymentCurrencyIdState, setPaymentCurrencyIdState] = useState('');
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const columns: IColumn[] = [
    { id: 'salesOrganization', label: 'Sales Organization', align: 'left' },
    { id: 'issuedCurrency', label: 'Currencies', align: 'left' },
    { id: 'paymentCurrency', label: 'Default', align: 'left' },
    { id: 'action', label: 'Action', align: 'center' },
  ];

  const findRegisters = async (page: number, limit: number) => {
    setLoadingState(true);
    paymentCurrency.findAll({ page, limit }).then((response) => {
      setListPaymentCurrency(response.data.body.result);
      setTotalRegister(response.data.body.total);
      setLoadingState(false);
    });
  };

  const handleShowDialogPaymentCurrency = () => {
    if (!showDialogPaymentCurrency === false) {
      setTypeAction('');
      setSalesOrganizationState('');
      setCurrency('');
      setCurrencyDefaultState('');
    }

    setShowDialogPaymentCurrency(!showDialogPaymentCurrency);
  };

  const getRowsPerPage = (rowsPerPage: number) => {
    setPageState(0);
    setTotalPerPageState(rowsPerPage);
    findRegisters(1, rowsPerPage);
  };

  const handlePageChange = (page: number) => {
    setPageState(page);
    findRegisters(page + 1, totalPerPageState);
  };

  const handleShowDialog = () => {
    setTypeAction('new');
    handleShowDialogPaymentCurrency();
  };

  // eslint-disable-next-line consistent-return
  const handleSubmitNewPaymentCurrency = async () => {
    setLoadingState(true);

    const validationErrors: string[] = [];
    if (!salesOrganizationState) validationErrors.push('Sales Organization');

    if (!currencyState) validationErrors.push('Currency');

    if (!currencyDefaultState) validationErrors.push('Default Currency');

    if (validationErrors.length > 0) {
      validationErrors.forEach((e) =>
        enqueueSnackbar(`${e} não informado`, { variant: 'error' }),
      );

      return {};
    }

    try {
      const savedPayment = await paymentCurrency.saveNewPaymentCurrency({
        salesOrganization: salesOrganizationState,
        currency: currencyState,
        currencyDefault: currencyDefaultState,
      });

      const payment = savedPayment.data.body;

      const newList = [payment, ...listPaymentCurrency];
      setListPaymentCurrency(newList);

      setTotalRegister(totalRegister + 1);

      setLoadingState(false);
      setTypeAction('');
      setSalesOrganizationState('');
      setCurrency('');
      setCurrencyDefaultState('');
      setShowDialogPaymentCurrency(!showDialogPaymentCurrency);

      enqueueSnackbar(`Payment Currency cadastrado com sucesso!`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Erro ao salvar: ${error.message}`, { variant: 'error' });
      setLoadingState(false);
      setTypeAction('');
      setSalesOrganizationState('');
      setCurrency('');
      setCurrencyDefaultState('');
      setShowDialogPaymentCurrency(!showDialogPaymentCurrency);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleSubmitUpdatePaymentCurrency = async () => {
    setLoadingState(true);

    const validationErrors: string[] = [];
    if (!salesOrganizationState) validationErrors.push('Sales Organization');

    if (!currencyState) validationErrors.push('Currency');

    if (!currencyDefaultState) validationErrors.push('Default Currency');

    if (validationErrors.length > 0) {
      validationErrors.forEach((e) =>
        enqueueSnackbar(`${e} não informado`, { variant: 'error' }),
      );

      return {};
    }

    try {
      const updatePayment = await paymentCurrency.updatePaymentCurrency({
        salesOrganization: salesOrganizationState,
        currency: currencyState,
        currencyDefault: currencyDefaultState,
        id: paymentCurrencyIdState,
      });

      const payment = updatePayment.data.body;

      const newList = listPaymentCurrency.map((payC) => {
        if (payC.id === payment.id) return payment;
        return payC;
      });
      setListPaymentCurrency(newList);
      setTotalRegister(totalRegister + 1);
      setLoadingState(false);
      setTypeAction('');
      setSalesOrganizationState('');
      setCurrency('');
      setCurrencyDefaultState('');
      setPaymentCurrencyIdState('');
      setShowDialogPaymentCurrency(!showDialogPaymentCurrency);
      enqueueSnackbar(`Payment Currency cadastrado com sucesso!`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Erro ao salvar: ${error.message}`, { variant: 'error' });
      setLoadingState(false);
      setTypeAction('');
      setSalesOrganizationState('');
      setCurrency('');
      setCurrencyDefaultState('');
      setPaymentCurrencyIdState('');
      setShowDialogPaymentCurrency(!showDialogPaymentCurrency);
    }

    setLoadingState(false);
  };

  const handleDelete = (p: IPaymentCurrency) => {
    setOpenDialogDelete(true);
    setLoadingState(true);
    setPaymentCurrencyIdState(p.id ? p.id : '');
  };

  const handleConfirmDeleteGateway = async () => {
    try {
      const newList = listPaymentCurrency.filter(
        (payC) => payC.id !== paymentCurrencyIdState,
      );

      await paymentCurrency.deletePaymentCurrency(paymentCurrencyIdState);
      setOpenDialogDelete(false);

      setListPaymentCurrency(newList);
      setTotalRegister(totalRegister - 1);
      setLoadingState(false);
      enqueueSnackbar(`Payment Currency foi deletado com sucesso!`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Erro ao deletar: ${error.message}`, {
        variant: 'error',
      });
    }
  };

  const handleEdit = (p: IPaymentCurrency) => {
    setTypeAction('edit');
    setSalesOrganizationState(p.salesOrganization);
    setCurrency(p.issuedCurrency.toString());
    setCurrencyDefaultState(p.paymentCurrency);
    setShowDialogPaymentCurrency(!showDialogPaymentCurrency);
    setPaymentCurrencyIdState(p.id ? p.id : '');
  };

  const mapMenuButton = (objs: IPaymentCurrency[]) =>
    objs.map((p: IPaymentCurrency) => ({
      ...p,
      issuedCurrency: p.issuedCurrency.toString(),
      action: (
        <MenuButton
          id={p.id as string}
          handleDelete={() => handleDelete(p)}
          handleEdit={() => handleEdit(p)}
          title="Ações"
        />
      ),
    }));

  useEffect(() => {
    findRegisters(pageState + 1, totalPerPageState);
  }, [pageState, totalPerPageState]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <BoxShadow>
          {loadingState ? (
            <Loading />
          ) : (
            <TableData
              rows={mapMenuButton(listPaymentCurrency)}
              columns={columns}
              title="Lista de Payment Currency"
              showBtn
              totalRows={totalRegister}
              handleRowsPerPageChange={getRowsPerPage}
              page={pageState}
              handlePageChange={handlePageChange}
              rowsPerPage={totalPerPageState}
              handleClickShowDialog={handleShowDialog}
              buttonTitle="Novo Payment Currency"
            />
          )}
        </BoxShadow>
      </Grid>

      <Dialog
        open={showDialogPaymentCurrency}
        onClose={handleShowDialogPaymentCurrency}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Informar os dados</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                name="title-class"
                label="Sales Organization"
                value={salesOrganizationState}
                className="input-admin-custom"
                onChange={(input) => {
                  setSalesOrganizationState(input.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className="mt-16">
            <Grid item xs={12}>
              <TextField
                name="title-class"
                label="Currencies (separado por vírgula)"
                value={currencyState}
                className="input-admin-custom"
                onChange={(input) => {
                  setCurrency(input.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className="mt-16">
            <Grid item xs={12}>
              <TextField
                name="title-class"
                label="Currency Default"
                value={currencyDefaultState}
                className="input-admin-custom"
                onChange={(input) => {
                  setCurrencyDefaultState(input.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="flex-end" style={{ padding: '16px' }}>
            <Grid item xs={12} sm={4} style={{ marginRight: '16px' }}>
              <Button
                onClick={handleShowDialogPaymentCurrency}
                text="Cancelar"
                className="cancel"
                type="button"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                text="Salvar"
                className="summary-confirm-active"
                type="button"
                onClick={
                  typeAction === 'new'
                    ? handleSubmitNewPaymentCurrency
                    : handleSubmitUpdatePaymentCurrency
                }
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogDelete}
        onClose={() => setOpenDialogDelete(!openDialogDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tem certeza que deseja deletar?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Caso deseja deletar, tenha certeza que nao ira afetar o pagamento.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonMaterialUi
            onClick={() => setOpenDialogDelete(!openDialogDelete)}
            color="primary"
          >
            Cancelar
          </ButtonMaterialUi>
          <ButtonMaterialUi
            onClick={handleConfirmDeleteGateway}
            color="primary"
            autoFocus
          >
            Sim, deletar!
          </ButtonMaterialUi>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default PaymentCurrency;
