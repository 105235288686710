import { AxiosResponse } from 'axios';
import api from '../api';

type IFindAllProps = {
  page: number;
  limit: number;
};

type ISaveNewPaymentCurrency = {
  salesOrganization: string;
  currency: string;
  currencyDefault: string;
  id?: string;
};

export type IPaymentCurrency = {
  id?: string;
  salesOrganization: string;
  issuedCurrency: string[];
  paymentCurrency: string;
  createdAt?: Date;
  updatedAt?: Date;
};

const paymentCurrency = {
  findAll: async ({
    page = 1,
    limit = 10,
  }: IFindAllProps): Promise<AxiosResponse> => {
    const queryParams = `?page=${page}&limit=${limit}`;

    return api.get(`/payment-currency${queryParams}`);
  },

  saveNewPaymentCurrency: async ({
    salesOrganization,
    currency,
    currencyDefault,
  }: ISaveNewPaymentCurrency): Promise<AxiosResponse> => {
    return api.post<IPaymentCurrency>(`/payment-currency`, {
      salesOrganization,
      currency,
      currencyDefault,
    });
  },

  updatePaymentCurrency: async ({
    salesOrganization,
    currency,
    currencyDefault,
    id,
  }: ISaveNewPaymentCurrency): Promise<AxiosResponse> => {
    return api.put<IPaymentCurrency>(`/payment-currency/${id}`, {
      salesOrganization,
      currency,
      currencyDefault,
    });
  },

  deletePaymentCurrency: async (id: string): Promise<AxiosResponse> => {
    return api.delete(`/payment-currency/${id}`);
  },
};

export default paymentCurrency;
