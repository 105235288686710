import React from 'react';
import { ButtonUi } from './style';

interface IButtonProps {
  text?: string;
  className:
    | 'back'
    | 'summary-confirm-disabled'
    | 'summary-confirm-active'
    | 'estorno'
    | 'actions'
    | 'search-report'
    | 'cancel'
    | 'new-card'
    | 'tcs'
    | 'wire-transfer'
    | 'bank-info-cancel'
    | 'send-receipt-button'
    | 'send-receipt-cancel-button'
    | 'confirm-button'
    | 'send-later-button';
  type: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  disabled?: boolean;
  icon?: JSX.Element;
}

const Button = ({
  text,
  className,
  onClick,
  type = 'button',
  disabled = false,
  icon,
}: IButtonProps): JSX.Element => {
  return (
    <ButtonUi>
      <button
        disabled={disabled}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={className}
        onClick={onClick}
      >
        {text && text}
        {icon}
      </button>
    </ButtonUi>
  );
};

export default Button;
