import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

import { BoxShadowGraph } from './style';
import api from '../../../services/api';
import Loading from '../../Loading/index';

interface IGraphPorAplicacaoProps {
  title: string;
  width: string;
  height: string;
}

const GraphPorAplicacao = ({
  title,
  width,
  height,
}: IGraphPorAplicacaoProps): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function mountGraph(data: any) {
      setLoading(false);

      am4core.useTheme(am4themesAnimated);

      const chart = am4core.create('chartdivstatus', am4charts.XYChart);

      chart.colors.step = 2;

      chart.legend = new am4charts.Legend();
      chart.legend.position = 'bottom';
      chart.legend.paddingBottom = 0;
      chart.legend.labels.template.maxWidth = 95;

      const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      xAxis.dataFields.category = 'category';
      xAxis.renderer.cellStartLocation = 0.1;
      xAxis.renderer.cellEndLocation = 0.9;
      xAxis.renderer.grid.template.location = 0;

      const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = 0;

      function createSeries(value: any, name: any) {
        const series: any = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = value;
        series.dataFields.categoryX = 'category';
        series.name = name;

        series.events.on('hidden', arrangeColumns);
        series.events.on('shown', arrangeColumns);

        const bullet = series.bullets.push(new am4charts.LabelBullet());
        bullet.interactionsEnabled = false;
        bullet.dy = 30;
        bullet.label.text = '{valueY}';
        bullet.label.fill = am4core.color('#ffffff');

        return series;
      }

      chart.data = data;

      createSeries('notFinished', t('Not Finished'));
      createSeries('canceled', t('Canceled'));
      createSeries('accepted', t('Accepted'));
      createSeries('voided', t('Refunded'));
      createSeries('reject', t('Rejected'));
      createSeries('created', t('Created'));

      function arrangeColumns() {
        const seriesChart: any = chart.series.getIndex(0);

        const w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation);
        if (seriesChart.dataItems.length > 1) {
          const x0 = xAxis.getX(seriesChart.dataItems.getIndex(0), 'categoryX');
          const x1 = xAxis.getX(seriesChart.dataItems.getIndex(1), 'categoryX');
          const delta = ((x1 - x0) / chart.series.length) * w;
          if (am4core.isNumber(delta)) {
            const middle = chart.series.length / 2;

            let newIndex = 0;
            chart.series.each((serieChart) => {
              if (!serieChart.isHidden && !serieChart.isHiding) {
                // eslint-disable-next-line no-param-reassign
                serieChart.dummyData = newIndex;
                newIndex += 1;
              } else {
                // eslint-disable-next-line no-param-reassign
                serieChart.dummyData = chart.series.indexOf(serieChart);
              }
            });
            const visibleCount = newIndex;
            const newMiddle = visibleCount / 2;

            chart.series.each((series) => {
              const trueIndex = chart.series.indexOf(series);
              const newIndexR: any = series.dummyData;

              const dx = (newIndexR - trueIndex + middle - newMiddle) * delta;

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing,
              );
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing,
              );
            });
          }
        }
      }
    }

    api.get('dashboard/by-status').then((response) => {
      mountGraph(response.data.body);
    });
  }, [t]);

  return (
    <BoxShadowGraph>
      <div className="grafico-stock-home">
        <h2>{title}</h2>
        {loading ? (
          <Loading height="350px" />
        ) : (
          <div
            id="chartdivstatus"
            style={{ width: `${width}`, height: `${height}` }}
          />
        )}
      </div>
    </BoxShadowGraph>
  );
};

export default GraphPorAplicacao;
