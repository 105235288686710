// import { TextField } from '@material-ui/core';
import styled from 'styled-components';

// export const PaymentUi = styled.div`
//   padding: 10px;
//   background-color: #fff;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
//   border-radius: 5px;
//   margin-top: 10px;
//   cursor: pointer;
//   border-left: 5px solid #1473e6;

//   .card-brand {
//     border-radius: 50%;
//     background-color: #f5f5f5;
//     height: 50px;
//     width: 50px;
//     position: relative;

//     &__name {
//       text-transform: capitalize;
//       margin-left: 30px !important;
//     }

//     img,
//     svg {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       width: 40px;
//     }
//   }

//   label.MuiFormControlLabel-root {
//     display: flex;
//     align-items: center;
//   }

//   span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     justify-content: flex-start;

//     & > span {
//       margin-left: 15px;
//       color: #6e6e6e;
//       vertical-align: middle;
//     }
//   }
// `;

export const PaymentConfirmationUi = styled.div`
  .paper {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: 25px 0 18px;
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    color: #292927;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    color: #6e6e6e;
    margin-top: 25px;
  }
`;
