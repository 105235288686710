import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { SummaryUi } from './style';
import Customer from '../Customer';
import LogosGateway from '../../LogosGateway';

export interface ISummary {
  paymentCurrency?: string;
  issuedCurrency?: string;
  totalAmount?: string;
  paymentAmount?: string;
  exchangeRateFormatted?: string;
  totalIrfValue?: string;
  paymentType?: 'token' | 'card';
  customerName?: string;
  gateway?: string;
  totalInterestAmount?: string;
  isWireTransfer: boolean;
  salesOrganization: string;
}

const Summary = ({
  paymentCurrency,
  issuedCurrency,
  totalAmount,
  totalIrfValue,
  paymentAmount,
  exchangeRateFormatted,
  customerName,
  gateway,
  totalInterestAmount,
  isWireTransfer,
  salesOrganization,
}: ISummary): JSX.Element => {
  const { t } = useTranslation('checkout');

  return (
    <Grid container>
      <Grid item xs={12}>
        <SummaryUi>
          <Grid container className="summary">
            <Grid container className="summary__customer">
              <Grid item xs={12}>
                <h4>{t('Customer')}</h4>
              </Grid>
              <Grid item xs={12}>
                <Customer name={customerName} />
              </Grid>
            </Grid>

            <Grid container className="summary__values">
              <Grid item xs={12}>
                <h4 className="summary-title">{t('summary')}</h4>
              </Grid>

              <Grid item xs={12}>
                <h5>
                  {`${t('Payable in')} ${
                    isWireTransfer && issuedCurrency === 'USD'
                      ? issuedCurrency
                      : paymentCurrency
                  }`}
                </h5>
              </Grid>

              <Grid item xs={6} lg={8}>
                <h5>{t('Details')}</h5>
              </Grid>
              <Grid item xs={6} lg={4} className="link">
                <a href="#details">{t('View')}</a>
              </Grid>

              <Grid item xs={6} lg={8}>
                <h5>{t('Total ($)', { issuedCurrency })}</h5>
              </Grid>
              <Grid item xs={6} lg={4}>
                <h6>{totalAmount}</h6>
              </Grid>

              {totalInterestAmount !== '$0.00' ? (
                <>
                  <Grid item xs={6} lg={8}>
                    <h5>{t('Total Interest ($)', { issuedCurrency })}</h5>
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <h6>{totalInterestAmount}</h6>
                  </Grid>
                </>
              ) : null}

              {totalIrfValue !== '$0.00' ? (
                <>
                  <Grid item xs={6} lg={8}>
                    <h5>{t('Withhold Taxes ($)', { issuedCurrency })}</h5>
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <h6>{totalIrfValue}</h6>
                  </Grid>
                </>
              ) : null}

              {paymentCurrency !== issuedCurrency && !isWireTransfer ? (
                <>
                  <Grid item xs={6} lg={8}>
                    <h5>{t('Exchange Rate')}</h5>
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <h6>{exchangeRateFormatted}</h6>
                  </Grid>
                </>
              ) : (
                ''
              )}

              {isWireTransfer &&
              !['USD', 'BRL'].includes(issuedCurrency!) &&
              salesOrganization === 'SJK1' ? (
                <>
                  <Grid item xs={6} lg={8}>
                    <h5>{t('Exchange Rate')}</h5>
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <h6>{exchangeRateFormatted}</h6>
                  </Grid>
                </>
              ) : (
                ''
              )}

              <Grid item xs={6} lg={8}>
                <h5>
                  {`${t('Grand Total ($)')} ${
                    isWireTransfer && issuedCurrency === 'USD'
                      ? issuedCurrency
                      : paymentCurrency
                  }`}
                </h5>
              </Grid>
              <Grid item xs={6} lg={4}>
                <h6>
                  {isWireTransfer && issuedCurrency === 'USD'
                    ? totalAmount
                    : paymentAmount}
                </h6>
              </Grid>
            </Grid>

            <Grid container className="summary__gateway">
              <Grid item xs={12}>
                <h4>{t('Gateway for Payment')}</h4>
              </Grid>
              <Grid item xs={12}>
                <LogosGateway gateway={gateway} />
              </Grid>
            </Grid>
          </Grid>
        </SummaryUi>
      </Grid>
    </Grid>
  );
};

export default Summary;
