import styled from 'styled-components';

interface DetailsCardProps {
  totalLine: boolean;
}

export const DetailsCardUi = styled.div<DetailsCardProps>`
  margin-top: 70px;
  .row {
    margin: 20px 20px;
    display: flex;
    justify-content: flex-end;
    .column {
      font-weight: bold;
      &:last-child {
        margin-left: 70px;
      }
    }
  }

  .table {
    display: block;
    width: 100%;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    background-color: #fff;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #292927;
    padding: 10px 0 30px 5px;
  }

  .details {
    border-bottom: 1px solid #f1f1f1;

    h5 {
      font-size: 14px;
      color: #76a6bf;
      font-weight: 400;
    }

    table {
      width: 100%;
      overflow: auto;

      thead {
        tr {
          th {
            padding: 12px 20px;
            text-align: left;
            font-size: 13px;
            font-weight: bold;
            color: #292927;
          }
        }
      }

      tbody {
        overflow: auto;
        tr {
          :nth-of-type(odd) {
            background: #faf9fa;
          }
          ${(props) =>
            props.totalLine &&
            `&:last-child {
              &>td {
                font-weight: bold;
                color: #292927;
              }
            }`}

          td {
            padding: 12px 20px;
            text-align: left;
            font-size: 13px;
            color: #6e6e6e;
          }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .details {
      overflow: auto;
      margin: 16px;
    }
  }
`;
