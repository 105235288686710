import styled from 'styled-components';

export const PaymentMethodUi = styled.div`
  padding: 6px 0;
  width: 100%;

  .accepted-cards {
    color: #6e6e6e;
    font-size: 13px;
    display: flex;
    align-items: center;
    img {
      background: Linear-gradient(#dbdbdb, #fff) border-box;
      width: 35.6px;
      height: 25.6px;
      object-fit: contain;
      margin: 0 2px;
      border-radius: 2px;
      border: 1px solid #dedede;
    }
  }

  legend.MuiFormLabel-root {
    color: #292927;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .MuiFormGroup-root.radio-group {
    display: flex;
    flex-direction: row;
  }

  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 12px;
    line-height: 14px;
    color: #6e6e6e;
  }
`;
