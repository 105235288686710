import React from 'react';
import { HTMLComponent } from 'react-typescript-raw-html';
import { ContainerMsgAlert } from './style';

interface IMsgAlertProps {
  className: string;
  title: string;
  message: string;
}

const MsgAlert = ({
  className,
  title,
  message,
}: IMsgAlertProps): JSX.Element => {
  return (
    <ContainerMsgAlert>
      <div className={className}>
        <h3>{title}</h3>
        <HTMLComponent rawHTML={message} />
      </div>
    </ContainerMsgAlert>
  );
};

export default MsgAlert;
