import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

import { BoxShadowGraph } from './style';
import api from '../../../services/api';

import Loading from '../../Loading';

interface IGraphTotalRecebidosProps {
  title: string;
  width: string;
  height: string;
}

const GraphTotalRecebidos = ({
  title,
  width,
  height,
}: IGraphTotalRecebidosProps): JSX.Element => {
  const [loading, setLoading] = useState(true);

  function mountGraph(data: any) {
    setLoading(false);
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themesAnimated);
    // Themes end

    // Create chart instance
    const chart: any = am4core.create('chartdiv', am4charts.XYChart);

    // i18n
    chart.dateFormatter.language = new am4core.Language();

    // Add data
    chart.data = data;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = 'right';
    chart.exporting.menu.verticalAlign = 'top';

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    const series: any = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'total';
    series.dataFields.dateX = 'paidAtDate';
    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.tooltipText = '{valueY}';
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.fillOpacity = 0.5;
    series.tooltip.label.padding(12, 12, 12, 12);

    // Add scrollbar
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(series);

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;

    valueAxis.show();
  }

  useEffect(() => {
    const getDataGraph = () => {
      api.get('/dashboard/total-received').then((response) => {
        mountGraph(response.data.body);
      });
    };

    getDataGraph();
  }, []);

  return (
    <BoxShadowGraph>
      <div className="grafico-stock-home">
        <h2>{title}</h2>
        {loading ? (
          <Loading height="350px" />
        ) : (
          <div
            id="chartdiv"
            style={{ width: `${width}`, height: `${height}` }}
          />
        )}
      </div>
    </BoxShadowGraph>
  );
};

export default GraphTotalRecebidos;
