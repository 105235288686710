/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const JPMorganCheckout = (callback?: (...args: unknown[]) => unknown) => {
  const scriptId = 'jpmorgan-checkout';
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');

    script.type = 'module';

    const checkoutUrl = process.env.REACT_APP_JPMC_CHECKOUT_URL;

    script.src = checkoutUrl || '';

    script.id = scriptId;

    document.body.appendChild(script);

    script.onload = () => {
      const t: any = window;
      if (t.DropInUI) {
        if (callback) callback(t.DropInUI);
      }
    };
  }

  if (existingScript && callback) callback();
};

export default JPMorganCheckout;
