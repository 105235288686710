import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';

import TableWebhoks from '../../Table';
import Button from '../../Button';
import MenuButton from '../../MenuButton';
import Loading from '../../Loading';
import NoData from '../../NoData';

import WebhookSystemService, {
  IWebhookSystem,
} from '../../../services/WebhookSystems/WebhookSystemsService';

interface IWebhookColumn {
  id: 'system' | 'endpoint' | 'action';
  label: string;
  minWidth?: string | undefined;
  maxWidth?: string | undefined;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}

const columns: IWebhookColumn[] = [
  { id: 'system', label: 'System', align: 'left' },
  { id: 'endpoint', label: 'Endpoint', align: 'left' },
  { id: 'action', label: 'Action', align: 'center' },
];

const WebHooks = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [systemInput, setSystemInput] = useState('');
  const [webhookId, setWebhookId] = useState('');
  const [endpointInput, setEndpointInput] = useState('');
  const [integrationRadio, setIntegrationRadio] = useState('');
  const [authTypeRadio, setAuthTypeRadio] = useState('');
  const [secretInput, setSecretInput] = useState('');
  const [webhooks, setWebhooks] = useState<IWebhookSystem[]>([]);

  const handleEdit = (id: string) => {
    const obj: IWebhookSystem | undefined = webhooks.find((e) => e.id === id);
    if (obj) {
      setWebhookId(obj.id || '');
      setSystemInput(obj.system);
      setEndpointInput(obj.endpoint);
      setIntegrationRadio(obj.type);
      setAuthTypeRadio(obj.authentication);
      setSecretInput(obj.secret);
    }
  };

  const removeDeleted = (id: string) => (e: IWebhookSystem) => e.id !== id;

  const handleDelete = async (id: string) => {
    try {
      const response = await WebhookSystemService.delete(id);
      const isDeleted: boolean = response.data.body;
      if (isDeleted) {
        const list = webhooks.filter(removeDeleted(id));
        setWebhooks(list);
      }
    } catch (err) {
      const msg = err.response.data.message ?? 'Request Error.';
      enqueueSnackbar(msg, { variant: 'error' });
    }
  };

  const mapMenuButton = (objs: IWebhookSystem[]) =>
    objs.map((e) => ({
      ...e,
      action: (
        <MenuButton
          id={e.id as string}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          title="Ações"
        />
      ),
    }));

  const handleSystemInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSystemInput((event.target as HTMLInputElement).value);
  };

  const handleEndpointInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndpointInput((event.target as HTMLInputElement).value);
  };

  const handleIntegrationRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIntegrationRadio((event.target as HTMLInputElement).value);
  };

  const handleAuthRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthTypeRadio((event.target as HTMLInputElement).value);
  };

  const handleSecretInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecretInput((event.target as HTMLInputElement).value);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors: string[] = [];
    if (!systemInput) {
      validationErrors.push('Sistema');
    }
    if (!endpointInput) {
      validationErrors.push('Endpoint');
    }
    if (!authTypeRadio) {
      validationErrors.push('Authetication');
    }
    if (!integrationRadio) {
      validationErrors.push('Integration');
    }
    if (!secretInput) {
      validationErrors.push('Secret');
    }
    if (validationErrors.length > 0) {
      validationErrors.forEach((e) =>
        enqueueSnackbar(`${e} não informado`, { variant: 'error' }),
      );
      return;
    }

    const obj: IWebhookSystem = {
      system: systemInput,
      endpoint: endpointInput,
      type: integrationRadio,
      authentication: authTypeRadio,
      secret: secretInput,
      id: webhookId || undefined,
    };

    try {
      const response = await WebhookSystemService.update(obj);
      const newWebhookSystem = response.data.body;
      const list = [
        newWebhookSystem,
        ...webhooks.filter(removeDeleted(obj.id || '')),
      ];
      setWebhooks(list);
      clearFormFileds();
    } catch (err) {
      const msg = err.response.data.message ?? 'Request Error.';
      enqueueSnackbar(msg, { variant: 'error' });
    }
  };

  const clearFormFileds = () => {
    setSystemInput('');
    setEndpointInput('');
    setIntegrationRadio('');
    setAuthTypeRadio('');
    setSecretInput('');
    setWebhookId('');
  };

  const listWebhooks = () => {
    WebhookSystemService.index()
      .then((r) => {
        const { body } = r.data;
        setWebhooks(body || []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    listWebhooks();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} xl={4}>
              <TextField
                name="system"
                label="System"
                value={systemInput}
                className="input-admin-custom"
                placeholder="Informe o sistema"
                onChange={handleSystemInput}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={8}>
              <TextField
                name="endpoint"
                label="Endpoint"
                value={endpointInput}
                className="input-admin-custom"
                placeholder="Informe o End-Point da aplicação"
                onChange={handleEndpointInput}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mt-16">
            <Grid item xs={12} lg={3} xl={2}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Integration through</FormLabel>
                <RadioGroup
                  aria-label="integration"
                  name="integrationRadio"
                  value={integrationRadio}
                  onChange={handleIntegrationRadio}
                >
                  <FormControlLabel
                    value="rabbitmq"
                    control={<Radio />}
                    label="RabbitMQ"
                  />
                  <FormControlLabel
                    value="api"
                    control={<Radio />}
                    label="API"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} xl={2}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Authentication</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="authTypeRadio"
                  value={authTypeRadio}
                  onChange={handleAuthRadio}
                >
                  <FormControlLabel
                    value="basic"
                    control={<Radio />}
                    label="Basic"
                  />
                  <FormControlLabel
                    value="bearer"
                    control={<Radio />}
                    label="Bearer"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} xl={8}>
              <TextField
                name="secret"
                label="Secret"
                value={secretInput}
                className="input-admin-custom"
                placeholder="Secret da aplicação"
                onChange={handleSecretInput}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end" spacing={2} className="mt-16">
            <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
              <Button text="Salvar" className="estorno" type="submit" />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
              <Button
                text="Cancelar"
                className="cancel"
                type="button"
                onClick={clearFormFileds}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid container className="mt-16">
        <Grid item xs={12}>
          {loading === false && webhooks.length > 0 ? (
            <TableWebhoks
              rows={mapMenuButton(webhooks)}
              columns={columns}
              showBtn={false}
              showPagination={false}
            />
          ) : (
            ''
          )}

          {loading && webhooks.length === 0 ? <Loading /> : ''}
          {loading === false && webhooks.length === 0 ? (
            <NoData text="No Data" />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WebHooks;
