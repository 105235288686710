import React from 'react';

import { AdministratorContainer } from './style';
import BoxShadow from '../../../../components/BoxShadow';
import WebHooksForm from '../../../../components/Forms/WebHooks';

const WebHook = (): JSX.Element => {
  return (
    <AdministratorContainer>
      <BoxShadow>
        <WebHooksForm />
      </BoxShadow>
    </AdministratorContainer>
  );
};

export default WebHook;
