const GetnetFingerprint = (
  checkoutId: string,
  callback?: (...args: unknown[]) => unknown,
): void => {
  const scriptId = 'getnet-devicefingerprint';
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');

    script.type = 'text/javascript';

    const env = process.env.REACT_APP_ENVIRONMENT || 'development';

    const getnetFPEnv = /(production)/.test(env) ? 'k8vif92e' : '1snn5n9w';

    const session = process.env.REACT_APP_GETNET_SELLERID + checkoutId;

    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${getnetFPEnv}&session_id=${session}`;

    script.id = scriptId;

    document.head.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export default GetnetFingerprint;
