import styled from 'styled-components';

export const BankInfoUI = styled.div`
  padding: 10px;
  font-size: 13px;

  .display-flex {
    display: flex;
  }

  .icon {
    font-size: 18px;
    margin: 0 5px;
  }

  .bank-info-data {
    margin: 0 0 6px 6px;

    & > * {
      margin-top: 5px;
    }
  }
`;
