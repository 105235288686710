import styled from 'styled-components';

export const AttachReceiptUI = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: row;

  .attach-recipe-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .icon {
    font-size: 25px;
    margin: 0 5px;
  }

  .wire-transfer-receipt {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    background-color: #28835c;
    border: 0;
    width: 100%;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    height: 100%;

    &:hover {
      background-color: #1e9a66;
    }
    &:focus {
      background-color: #095e3a;
    }
  }

  .attach-content {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .chosen-file {
    display: flex;
    align-items: center;
    color: #6e6e6e;
  }

  .choose-file-button {
    width: auto;
    height: 25px;
    background-color: #efefef;
    border-radius: 2px;
    font-size: 11px;

    &:hover {
      background-color: #dbdbdb;
    }
    &:focus {
      background-color: #dbdbdb;
    }
  }
`;
