import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';

export const FormGroupUiSalesGateway = styled(FormGroup)`
  margin-top: 16px !important;
`;

export const ContainerSites = styled.div`
  padding-top: 80px;
`;
