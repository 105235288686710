import React from 'react';
import { IconType } from 'react-icons';
import {
  MdAccessTime,
  MdDone,
  MdHourglassEmpty,
  MdClear,
} from 'react-icons/md';

export default ({ status }: { status: string }): JSX.Element => {
  const value: { [key: string]: { Icon: IconType; color: string } } = {
    TRANSFER_ACCEPT: {
      Icon: MdDone,
      color: 'green',
    },
    TRANSFER_WAITING_APPROVAL: {
      Icon: MdAccessTime,
      color: 'orange',
    },
    TRANSFER_WAITING_RECEIPT: {
      Icon: MdHourglassEmpty,
      color: 'blue',
    },
    TRANSFER_REJECT: {
      Icon: MdClear,
      color: 'red',
    },
    ACCEPT: {
      Icon: MdDone,
      color: 'green',
    }, 
    APPROVED: {
      Icon: MdDone,
      color: 'green',
    },
    CANCELED: {
      Icon: MdClear,
      color: 'red',
    },
    CREATED: {
      Icon: MdDone,
      color: 'green',
    },
    ERROR: {
      Icon: MdClear,
      color: 'red',
    },
    REJECT: {
      Icon: MdClear,
      color: 'red',
    },
    VOIDED: {
      Icon: MdClear,
      color: 'red',
    }
  };

  const { Icon, color } = value[status] ? value[status] : {
    Icon: MdClear,
    color: 'red',
  };

  return (
    <>
      <Icon color={color} />
    </>
  );
};
