import { AxiosResponse } from 'axios';
import api from '../api';

interface ITokenDataAPI {
  token: string;
}

interface IResponseAuthenticated {
  body: ITokenDataAPI;
}

const ssoService = {
  getUserAuthenticated: async (token: string): Promise<AxiosResponse> => {
    return api.get('/sso/authenticated', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postSsoLoginAuthenticated: async (hash: string): Promise<AxiosResponse> => {
    return api.post<IResponseAuthenticated>(
      '/sso/login/authenticated',
      {},
      {
        headers: {
          authenticate: hash,
        },
      },
    );
  },
};

export default ssoService;
