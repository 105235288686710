import React, { useState, useEffect, useCallback } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Swal from 'sweetalert2';
import { ContainerSites, FormGroupUiSalesGateway } from './style';

import BoxShadow from '../../../../components/BoxShadow';
import TableData from '../../../../components/Table';
import ISalesGateway from './interfaces/ISalesGateway';
import MenuButton from '../../../../components/MenuButton';
import { IColumn } from '../../../../components/Table/interfaces';
import salesGateway from '../../../../services/salesGateway';
import { IGatewayProps } from '../Gateways';
import gateways from '../../../../services/gateways';
import Button from '../../../../components/Button/index';

const Sites = (): JSX.Element => {
  const snackbar = useSnackbar();
  const [dataList, setDataList] = useState([] as ISalesGateway[]);
  const [totalRowsList, setTotalRowsList] = useState(0);
  const [pageState, setPage] = useState(0);
  const [totalPerPageState, setTotalPerPageState] = useState(10);
  const [openDialogSalesGateway, setOpenDialogSalesGateway] = useState(false);
  const [actionState, setActionState] = useState('');
  const [dataSilesGateway, setDataSelesGateway] = useState({
    title: '',
    gatewayId: '',
    site: '',
    currency: '',
    active: false,
    idApi: '',
    sellerId: '',
    token: '',
  } as ISalesGateway);
  const [listGateways, setListGateways] = useState<IGatewayProps[]>([]);

  const columns: IColumn[] = [
    { id: 'id', label: 'ID', align: 'left', maxWidth: '20%' },
    { id: 'title', label: 'Name', align: 'left' },
    { id: 'site', label: 'Site', align: 'left' },
    { id: 'currency', label: 'Currency', align: 'left' },
    { id: 'activeString', label: 'Active', align: 'left' },
    { id: 'createdAtFormatted', label: 'Created At', align: 'left' },
    { id: 'action', label: 'Action', align: 'center' },
  ];

  const handleDelete = useCallback(
    (e: ISalesGateway) => {
      setDataSelesGateway(e);

      const filterRegisterDeleted = dataList.filter(
        (sales) => sales.id !== e.id,
      );

      Swal.fire({
        title: 'Tem certeza?',
        text: 'Ao deletar o registro os dados serão removidos permanentemente.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'sim, deletar!',
      }).then((result) => {
        if (result.isConfirmed) {
          salesGateway
            .delete(e.id)
            .then(() => {
              setDataList(filterRegisterDeleted);
              setTotalRowsList(filterRegisterDeleted.length);
              setDataSelesGateway({
                title: '',
                gatewayId: '',
                site: '',
                currency: '',
                active: false,
                idApi: '',
                sellerId: '',
                token: '',
              });
              snackbar.enqueueSnackbar(
                'Sales Gateway foi deletado com sucesso.',
                {
                  variant: 'success',
                },
              );
            })
            .catch(() => {
              setDataSelesGateway({
                title: '',
                gatewayId: '',
                site: '',
                currency: '',
                active: false,
                idApi: '',
                sellerId: '',
                token: '',
              });
              snackbar.enqueueSnackbar('Erro ao deletar o sales gateway.', {
                variant: 'error',
              });
            });
        }
      });
    },
    [dataList, snackbar],
  );

  const handleEdit = useCallback(
    (e: ISalesGateway) => {
      setOpenDialogSalesGateway(!openDialogSalesGateway);
      setDataSelesGateway(e);
      setActionState('update');
    },
    [openDialogSalesGateway],
  );

  const handleConfirmSalesGatewayForm = useCallback(async () => {
    salesGateway
      .insert(dataSilesGateway)
      .then((response) => {
        setDataList([response.data.body, ...dataList]);
        setDataSelesGateway({
          title: '',
          gatewayId: '',
          site: '',
          currency: '',
          active: false,
          idApi: '',
          sellerId: '',
          token: '',
        });
        setOpenDialogSalesGateway(false);
        snackbar.enqueueSnackbar('Sales Gateway salvo com sucesso.', {
          variant: 'success',
          autoHideDuration: 3500,
        });
      })
      .catch((error) => {
        const { errors } = error.response.data;
        errors.forEach((err: any) => {
          snackbar.enqueueSnackbar(err.message, {
            variant: 'error',
            autoHideDuration: 3500,
            key: err.status,
          });
        });
      });
  }, [dataList, dataSilesGateway, snackbar]);

  const handleUpdateSalesGateway = useCallback(async () => {
    salesGateway
      .update(dataSilesGateway)
      .then((response) => {
        const newDataList = dataList.map((dt) => {
          if (dt.id === dataSilesGateway.id) return response.data.body;
          return dt;
        });
        setDataList(newDataList);
        setDataSelesGateway({
          title: '',
          gatewayId: '',
          site: '',
          currency: '',
          active: false,
          idApi: '',
          sellerId: '',
          token: '',
        });
        setOpenDialogSalesGateway(false);
        snackbar.enqueueSnackbar('Sales Gateway salvo com sucesso.', {
          variant: 'success',
          autoHideDuration: 3500,
        });
      })
      .catch((error) => {
        const { errors } = error.response.data;
        errors.forEach((err: any) => {
          snackbar.enqueueSnackbar(err.message, {
            variant: 'error',
            autoHideDuration: 3500,
            key: err.status,
          });
        });
      });
  }, [dataList, dataSilesGateway, snackbar]);

  const mapMenuButton = (objs: ISalesGateway[]) =>
    objs.map((e: ISalesGateway) => ({
      ...e,
      action: (
        <MenuButton
          id={e.id as string}
          handleDelete={() => handleDelete(e)}
          handleEdit={() => handleEdit(e)}
          title="Ações"
        />
      ),
    }));

  const getRowsPerPage = (rowsPerPage: number) => {
    setTotalPerPageState(rowsPerPage);
    salesGateway
      .findAll({ limit: rowsPerPage, page: pageState + 1 })
      .then((response) => {
        if (response.data.body.salesGateways.length === 0 && pageState > 0) {
          setPage(0);
          salesGateway
            .findAll({ limit: rowsPerPage, page: pageState })
            .then((result) => {
              setDataList(result.data.body.salesGateways);
            });
        } else {
          setDataList(response.data.body.salesGateways);
        }
      });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    salesGateway
      .findAll({ limit: totalPerPageState, page: page + 1 })
      .then((response) => {
        setDataList(response.data.body.salesGateways);
      });
  };

  const handleShowDialog = () => {
    setOpenDialogSalesGateway(!openDialogSalesGateway);
    setActionState('insert');
  };

  const handleChangeActiveSalesGateway = useCallback(
    (e) => {
      const dataSilesGatewayUpdate = {
        ...dataSilesGateway,
        [e.target.name]: e.target.checked,
      };
      setDataSelesGateway(dataSilesGatewayUpdate);
    },
    [dataSilesGateway],
  );

  const handleCloseDialogSalesGateway = useCallback(() => {
    if (!openDialogSalesGateway === false) {
      setDataSelesGateway({
        title: '',
        gatewayId: '',
        site: '',
        currency: '',
        active: false,
        idApi: '',
        sellerId: '',
        token: '',
      });
    }
    setOpenDialogSalesGateway(!openDialogSalesGateway);
    setActionState('');
  }, [openDialogSalesGateway]);

  const handleChangeInputFormSalesGateway = useCallback(
    (e) => {
      const dataSilesGatewayUpdate = {
        ...dataSilesGateway,
        [e.target.name]: e.target.value,
      };
      setDataSelesGateway(dataSilesGatewayUpdate);
    },
    [dataSilesGateway],
  );

  useEffect(() => {
    salesGateway.findAll({ limit: 10, page: 1 }).then((response) => {
      setDataList(response.data.body.salesGateways);
      setTotalRowsList(response.data.body.total);
    });

    gateways.findAll({ limit: 100, page: 1 }).then((response) => {
      setListGateways(response.data.body.gateways);
    });
  }, []);

  return (
    <ContainerSites>
      <BoxShadow>
        <TableData
          rows={mapMenuButton(dataList)}
          columns={columns}
          title="Configuração Sales Organization"
          showBtn
          totalRows={totalRowsList}
          handleRowsPerPageChange={getRowsPerPage}
          page={pageState}
          handlePageChange={handlePageChange}
          rowsPerPage={totalPerPageState}
          handleClickShowDialog={handleShowDialog}
          buttonTitle="Nova Configuração"
        />
      </BoxShadow>

      <Dialog
        open={openDialogSalesGateway}
        onClose={handleCloseDialogSalesGateway}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Cadastrar um novo Sale Gateway
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <TextField
                  placeholder="SJK1 GetNet"
                  fullWidth
                  label="Título"
                  value={dataSilesGateway.title}
                  name="title"
                  onChange={handleChangeInputFormSalesGateway}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  placeholder="SJK1"
                  fullWidth
                  label="Sales Organization"
                  value={dataSilesGateway.site}
                  name="site"
                  onChange={handleChangeInputFormSalesGateway}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              className="align-items-center"
            >
              <Grid item xs={12} md={4}>
                <TextField
                  placeholder="BRL"
                  fullWidth
                  label="Currency"
                  value={dataSilesGateway.currency}
                  name="currency"
                  onChange={handleChangeInputFormSalesGateway}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroupUiSalesGateway row>
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Switch
                        checked={dataSilesGateway.active}
                        name="active"
                        onChange={handleChangeActiveSalesGateway}
                        color="primary"
                      />
                    }
                    label="Sales Gateway Active?"
                    className="form-group-sales-gateway-active"
                  />
                </FormGroupUiSalesGateway>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gateway</InputLabel>
                  <Select
                    value={dataSilesGateway.gatewayId}
                    onChange={handleChangeInputFormSalesGateway}
                    autoWidth
                    name="gatewayId"
                  >
                    <MenuItem key="select-null-sales-gateway" value="">
                      Selecione
                    </MenuItem>
                    {listGateways.map((gateway) => (
                      <MenuItem key={gateway.id} value={gateway.id}>
                        {gateway.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  placeholder="Token do Gateway"
                  fullWidth
                  label="TOKEN"
                  value={dataSilesGateway.token}
                  name="token"
                  onChange={handleChangeInputFormSalesGateway}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  placeholder="ID API do Gateway"
                  fullWidth
                  label="ID API"
                  value={dataSilesGateway.idApi}
                  name="idApi"
                  onChange={handleChangeInputFormSalesGateway}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  placeholder="SELLER ID caso houver"
                  fullWidth
                  label="SELLER ID"
                  value={dataSilesGateway.sellerId}
                  name="sellerId"
                  onChange={handleChangeInputFormSalesGateway}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justify="flex-end">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={handleCloseDialogSalesGateway}
                    text="CANCELAR"
                    type="button"
                    className="cancel"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={
                      actionState === 'insert'
                        ? handleConfirmSalesGatewayForm
                        : handleUpdateSalesGateway
                    }
                    text="SALVAR"
                    type="button"
                    className="summary-confirm-active"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </ContainerSites>
  );
};

export default Sites;
