import styled from 'styled-components';

export const NavUiLeft = styled.nav`
  height: 100vh;
  display: block;
  background: rgba(65, 89, 158, 1);
  background: -moz-linear-gradient(
    top,
    rgba(65, 89, 158, 1) 0%,
    rgba(130, 175, 221, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(65, 89, 158, 1)),
    color-stop(100%, rgba(130, 175, 221, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(65, 89, 158, 1) 0%,
    rgba(130, 175, 221, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(65, 89, 158, 1) 0%,
    rgba(130, 175, 221, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(65, 89, 158, 1) 0%,
    rgba(130, 175, 221, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(65, 89, 158, 1) 0%,
    rgba(130, 175, 221, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#41599e', endColorstr='#82afdd', GradientType=0 );

  a {
    text-decoration: none;
    display: block;
  }

  .MuiListItem-button {
    color: rgba(255, 255, 255, 0.6);
  }

  .MuiListItemText-root {
    color: rgba(255, 255, 255, 0.6);
  }

  .MuiListItemIcon-root {
    color: rgba(255, 255, 255, 0.6);
  }

  .active {
    background-color: rgba(0, 0, 0, 0.05);

    .MuiListItemText-root {
      color: rgba(255, 255, 255, 1);
    }

    .MuiListItemIcon-root {
      color: rgba(255, 255, 255, 1);
    }
  }
`;
