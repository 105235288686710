import React, { useEffect, useCallback } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import {
  WireTransferButtonUI,
  WireTransferUI,
  WireTransferDinamicUI,
} from './style';
import CustomButton from '../../Button';
import PaymentService from '../../../services/payment';
import { PaymentMethods } from '../../../services/payment/interfaces/ICheckoutPaymentDTO';
import BankInfo from './BankInfo';
import PaymentOrderInfo from './OrderInfo';
import AttachReceipt from './AttachReceipt';
import { usePayment } from '../../../hooks/payment';
import CheckoutService from '../../../services/checkout/CheckoutService';

export interface WireTransferProps {
  checkoutId: string;
  paymentOrderNum: string;
  currency: string;
  salesOrganization: string;
  issuedCurrency: string;
}

const WireTransfer = ({
  checkoutId,
  paymentOrderNum,
  currency,
  salesOrganization,
  issuedCurrency,
}: WireTransferProps): JSX.Element => {
  const { t } = useTranslation('checkout');
  const { goBack } = useHistory();

  const {
    handleSubmitPayment,
    setSelectedFile,
    setPaymentOrderNum,
    selectedFile,
  } = usePayment();

  const handleFinish = useCallback(async () => {
    if (!selectedFile) {
      Swal.fire({
        title: 'Oops!',
        text: t('Please, select a file to send.'),
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      return;
    }
    handleSubmitPayment();
  }, [handleSubmitPayment, selectedFile, t]);

  const handleSendLater = useCallback(async () => {
    try {
      const sendLaterResponse =
        await PaymentService.sendWireTransferSendLaterMail({
          checkoutId,
          transactionNumber: paymentOrderNum,
        });

      Swal.fire({
        title: 'Yeah!',
        text: t('Successfully saved your checkout to send receipt later.'),
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        window.location.href = sendLaterResponse.data.body.successUrl;
      });
    } catch (error) {
      Swal.fire({
        title: 'Oops!',
        text: t('Something went wrong.'),
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => goBack());
    }
  }, [checkoutId, goBack, paymentOrderNum, t]);

  const handleCancel = async () => {
    await CheckoutService.cancelCheckout(checkoutId);
    goBack();
  };

  useEffect(() => {
    setPaymentOrderNum(paymentOrderNum);
  }, [paymentOrderNum, setPaymentOrderNum]);

  return (
    <>
      <WireTransferUI>
        <BankInfo
          currency={
            salesOrganization === 'SJK1' && issuedCurrency === 'USD'
              ? issuedCurrency
              : currency
          }
          salesOrganization={salesOrganization}
          type={PaymentMethods.WIRE_TRANSFER}
        />
      </WireTransferUI>
      <WireTransferDinamicUI>
        <Grid container direction="column">
          <Grid container direction="row" justifyContent="space-between">
            <Grid
              item
              xs={7}
              style={{
                width: '90px',
                whiteSpace: 'nowrap',
                paddingLeft: '16px',
              }}
            >
              <PaymentOrderInfo paymentOrderNumber={paymentOrderNum} />
              <Box
                fontWeight="fontWeightBold"
                style={{
                  color: 'black',
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '13px',
                }}
              >
                {t('Send Payment Receipt')}
              </Box>
              <div />
              <AttachReceipt setSelectedFile={setSelectedFile} />
              <Box
                style={{
                  color: 'black',
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '11px',
                  fontStyle: 'italic',
                }}
              >
                {`${t('Format')}: JPG, JPEG, PNG, PDF (${t(
                  'Maximum size of 5mb',
                )}).`}
              </Box>
            </Grid>

            <Box
              style={{
                color: 'red',
                fontSize: '13px',
                fontWeight: 'bold',
                maxWidth: '250px',
                paddingLeft: '16px',
              }}
            >
              <p>{`${t('IMPORTANT')}:`}</p>
              <p>
                {t(
                  'Send the receipt within 24 hours, after that, this transaction will be canceled and payment still pending.',
                )}
              </p>
              <p>{t('Wire transfer fund must be equal of checkout amount.')}</p>
            </Box>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <WireTransferButtonUI className="wire-transfer-buttons">
              <Grid style={{ paddingRight: '4px' }}>
                <CustomButton
                  text={t('Confirm')}
                  className="confirm-button"
                  type="button"
                  onClick={handleFinish}
                  disabled={!selectedFile}
                />
              </Grid>
              <Grid style={{ padding: '0 4px 0 4px' }}>
                <CustomButton
                  text={t('Send later')}
                  className="send-later-button"
                  type="button"
                  onClick={handleSendLater}
                />
              </Grid>
              <Grid style={{ paddingLeft: '4px' }}>
                <CustomButton
                  text={t('Cancel')}
                  className="send-receipt-cancel-button"
                  type="button"
                  onClick={handleCancel}
                />
              </Grid>
            </WireTransferButtonUI>
          </Grid>
        </Grid>
      </WireTransferDinamicUI>
    </>
  );
};

export default WireTransfer;
