import React, { useEffect } from 'react';

const Redirect = (): JSX.Element => {
  useEffect(() => {
    sessionStorage.clear();
    window.location.href = `${process.env.REACT_APP_PORTAL_EMBRAER}`;
  }, []);

  return <div />;
};

export default Redirect;
