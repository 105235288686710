import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';

import DialogContentText from '@material-ui/core/DialogContentText';
import ButtonMaterialUi from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import DialogBankInformation from '../../../../components/DialogBankInformation';
import bankingService from '../../../../services/banking/banking-service';

import { ContainerGateways } from './style';
import BoxShadow from '../../../../components/BoxShadow';
import TableData from '../../../../components/Table';
import MenuButton from '../../../../components/MenuButton/index';
import { BankInformationDTO } from '../../../../services/banking/dtos/bank-info-dto';
import {
  CompanyBankInformation,
  AccountsType,
} from '../../../../services/banking/interfaces/i-company-banking-information';

interface IColumn {
  id: string;
  label: string;
  minWidth?: string | undefined;
  maxWidth?: string | undefined;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}

export type IGatewayProps = {
  createdAt?: string;
  createdAtFormatted?: string;
  class: string;
  id: string;
  title: string;
  updatedAt?: string;
};

export default (): JSX.Element => {
  const { t } = useTranslation('bankInformation');

  const snackbar = useSnackbar();
  const [listBankInformation, setListBankInformation] = useState<
    CompanyBankInformation[]
  >([]);
  const [totalRowsList, setTotalRowsList] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [pageState, setPage] = useState(0);
  const [totalPerPageState, setTotalPerPageState] = useState(10);
  const [isEdit, setIsEdit] = useState(false);

  const [bankingInformation, setBankingInformation] = useState(
    {} as BankInformationDTO,
  );

  const columns: IColumn[] = [
    { id: 'bankName', label: t('Bank Name'), align: 'left' },
    { id: 'salesOrganization', label: t('Site'), align: 'left' },
    { id: 'currency', label: t('Currency'), align: 'left' },
    { id: 'beneficiaryName', label: t('Bank Beneficiary Name'), align: 'left' },
    { id: 'beneficiaryAddressOne', label: t('Bank Address'), align: 'left' },
    { id: 'account', label: t('Account'), align: 'left' },
    { id: 'agency', label: t('Agency'), align: 'left' },
    { id: 'swift', label: t('Swift'), align: 'left' },
    { id: 'aba', label: t('Aba'), align: 'left' },
    { id: 'accounts', label: t('Accounts'), align: 'left' },
    { id: 'action', label: t('Action'), align: 'center' },
  ];

  const handleDelete = (bankInfo: BankInformationDTO) => {
    setBankingInformation(bankInfo);
    setOpenDialogDelete(!openDialogDelete);
  };

  const handleCloseDialogDelete = (statusDialog: boolean) => {
    setOpenDialogDelete(statusDialog);
  };

  const handleConfirmDeleteBankInformation = async () => {
    try {
      if (bankingInformation.id)
        await bankingService.delete(bankingInformation.id);
      setTotalRowsList(totalRowsList - 1);

      bankingService.findAll({}).then((response) => {
        setListBankInformation(response.data.body.bankInformation);
        setTotalRowsList(response.data.body.total);
      });

      snackbar.enqueueSnackbar(t('Delete success'), {
        variant: 'success',
      });
    } catch (error) {
      snackbar.enqueueSnackbar(t('Delete error message'), {
        variant: 'error',
      });
    }
    setOpenDialogDelete(!openDialogDelete);
  };

  const handleEdit = (bankInfo: BankInformationDTO) => {
    setBankingInformation(bankInfo);
    setIsEdit(true);
    setOpenDialog(!openDialog);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    bankingService
      .findAll({ limit: totalPerPageState, page: page + 1 })
      .then((response) => {
        setListBankInformation(response.data.body.bankInformation);
        setTotalRowsList(response.data.body.total);
      });
  };

  const getRowsPerPage = (rowsPerPage: number) => {
    setTotalPerPageState(rowsPerPage);
  };

  const mapMenuButton = (objs: BankInformationDTO[]) =>
    objs
      ? objs.map((e: BankInformationDTO) => ({
          ...e,
          accounts: e.accounts
            ? e.accounts
                .map((a: AccountsType) => `${a.name} - ${a.account}`)
                .join(', ')
            : null,
          action: (
            <MenuButton
              id={e.id as string}
              handleDelete={() => handleDelete(e)}
              handleEdit={() => handleEdit(e)}
              title={t('Actions')}
            />
          ),
        }))
      : [];

  const handleCloseDialog = async () => {
    setOpenDialog(!openDialog);
    setIsEdit(false);
  };

  const handleShowDialog = () => {
    setIsEdit(false);
    setOpenDialog(!openDialog);
  };

  useEffect(() => {
    bankingService.findAll({}).then((response) => {
      setListBankInformation(response.data.body.bankInformation);
      setTotalRowsList(response.data.body.total);
    });
  }, []);

  return (
    <ContainerGateways>
      <BoxShadow>
        <TableData
          rows={mapMenuButton(listBankInformation)}
          columns={columns}
          title={t('Bank Information')}
          showBtn
          totalRows={totalRowsList}
          handleRowsPerPageChange={getRowsPerPage}
          page={pageState}
          rowsPerPage={totalPerPageState}
          handlePageChange={handlePageChange}
          handleClickShowDialog={handleShowDialog}
          buttonTitle={t('New Bank Info')}
        />
      </BoxShadow>

      <DialogBankInformation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        bankingInformation={bankingInformation}
        isEdit={isEdit}
        setOpenDialog={setOpenDialog}
        setListBankInformation={setListBankInformation}
        setTotalRowsList={setTotalRowsList}
        setIsEdit={setIsEdit}
      />

      <Dialog
        open={openDialogDelete}
        onClose={() => handleCloseDialogDelete(!openDialogDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('Delete title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Delete message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonMaterialUi
            onClick={() => handleCloseDialogDelete(!openDialogDelete)}
            color="primary"
          >
            {t('Cancel')}
          </ButtonMaterialUi>
          <ButtonMaterialUi
            onClick={handleConfirmDeleteBankInformation}
            color="primary"
            autoFocus
          >
            {t('Delete confirm')}
          </ButtonMaterialUi>
        </DialogActions>
      </Dialog>
    </ContainerGateways>
  );
};
