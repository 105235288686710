/* eslint-disable */
const creditCardType = require('credit-card-type');
/**
 * gets card brands by pattern
 * @param {string} cardNumber
 */
const getCardBrand = (cardNumber: string): string | undefined => {
  const cardBrandsMap: { [key: string]: string } = {
    visa: 'Visa',
    mastercard: 'MasterCard',
    'american-express': 'AmericanExpress',
    'diners-club': 'DinersClub',
    discover: 'Discover',
    jcb: 'JCB',
    unionpay: 'UnionPay',
    maestro: 'Maestro',
    elo: 'Elo',
    hipercard: 'HiperCard',
  };
  const foundBrand = creditCardType(cardNumber);
  const validBrand =
    foundBrand.length === 1 && cardBrandsMap[foundBrand[0].type];
  return validBrand ? cardBrandsMap[foundBrand[0].type] : undefined;
};

export default getCardBrand;
