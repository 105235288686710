import { AxiosResponse } from 'axios';
import api from '../api';
import ISalesGateway from '../../layouts/Admin/pages/Sites/interfaces/ISalesGateway';

type IFindAllProps = {
  page?: number;
  limit?: number;
};

const salesGateway = {
  findAll: async ({
    page = 1,
    limit = 10,
  }: IFindAllProps): Promise<AxiosResponse> => {
    const queryParams = `?page=${page}&limit=${limit}`;
    return api.get(`/sales-gateway${queryParams}`);
  },
  // Save a new register
  insert: async ({
    title,
    active,
    currency,
    gatewayId,
    idApi,
    sellerId,
    site,
    token,
  }: ISalesGateway): Promise<AxiosResponse> => {
    return api.post(`/sales-gateway`, {
      title,
      active,
      currency,
      gatewayId,
      idApi,
      sellerId,
      site,
      token,
    });
  },
  // Delete register
  delete: async (id?: string): Promise<AxiosResponse> => {
    return api.delete(`/sales-gateway/${id}`);
  },
  // Update Register
  update: async ({
    title,
    active,
    currency,
    gatewayId,
    idApi,
    sellerId,
    site,
    token,
    id,
  }: ISalesGateway): Promise<AxiosResponse> => {
    return api.put(`/sales-gateway/${id}`, {
      title,
      active,
      currency,
      gatewayId,
      idApi,
      sellerId,
      site,
      token,
    });
  },
};

export default salesGateway;
