import React, { createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useTranslation } from 'react-i18next';
import TermsAndConditionsService from '../services/terms-and-conditions/TermsAndConditionsService';
import { TermsAndConditionsAcceptRequestDTO } from '../services/terms-and-conditions/dto/termsAndConditionsAcceptRequestDTO';
import { TermsAndConditionTokenDTO } from '../services/terms-and-conditions/dto/termsAndConditionTokenDTO';

interface ITCSContextData {
  sendToAccept: (payload: TermsAndConditionsAcceptRequestDTO) => Promise<void>;
  checkAcceptance: (tcsToken: string) => Promise<boolean>;
}

interface ITCSProps {
  children: JSX.Element[] | JSX.Element;
}

const TCSContext = createContext<ITCSContextData>({} as ITCSContextData);

export const TCSProvider = ({ children }: ITCSProps): JSX.Element => {
  const { t } = useTranslation('globals');
  const history = useHistory();

  const checkAcceptance = async (tcsToken: string) => {
    const query = new URLSearchParams(tcsToken);
    const token = query.get('inv');

    if (!token || token === null) {
      const message = t('Missing terms and condition data!');

      Swal.close();
      Swal.fire({
        title: 'Oops!',
        text: message,
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        history.push('/redirect');
      });
      return false;
    }

    const decodedToken: TermsAndConditionTokenDTO = JSON.parse(
      window.atob(token),
    );

    const r = await TermsAndConditionsService.getAcceptanceOrContract(
      decodedToken,
    );

    if (r === true) return true;

    history.push('/terms-and-conditions', {
      tcsData: { ...r, ...decodedToken },
    });

    return false;
  };

  const sendToAccept = async (payload: TermsAndConditionsAcceptRequestDTO) => {
    const r = await TermsAndConditionsService.acceptTermsAndConditions(payload);

    if (r.accepted) {
      history.goBack();
    }

    if (!r.accepted) {
      throw new Error(r.message);
    }
  };

  return (
    <TCSContext.Provider
      value={{
        sendToAccept,
        checkAcceptance,
      }}
    >
      {children}
    </TCSContext.Provider>
  );
};

export function useTCS(): ITCSContextData {
  const context = useContext(TCSContext);

  if (!context) {
    throw new Error('useTCS must be used within an TCSProvider');
  }

  return context;
}
