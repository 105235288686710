import styled from 'styled-components';

export const WireTransferUI = styled.div`
  padding: 0 10px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-top: 10px;

  .payment-order-data,
  .payment-order-alert {
    margin-bottom: 20px;
  }

  .display-flex {
    display: flex;
  }

  .stepper {
    width: 70%;
    margin: auto;
  }

  .MuiStepLabel-label {
    font-size: 12px !important;
  }

  p {
    text-align: center;
    font-size: 13px;
    color: #6e6e6e;
    padding: 10px 0 30px;
  }

  .wire-transfer-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 6px;
    align-items: end;
  }

  @media only screen and (max-width: 400px) {
    .stepper {
      width: 100%;
    }
  }
`;

export const WireTransferDinamicUI = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .wire-transfer-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 6px;
    align-items: end;
  }

  @media only screen and (max-width: 400px) {
    .stepper {
      width: 100%;
    }
  }
`;

export const WireTransferButtonUI = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .icon {
    margin-left: 1px;
    font-size: 25px;
  }
`;
