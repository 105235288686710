import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { ContainerPage, TextInputTransactionCode } from './style';
import MsgAlert from '../../../../components/MsgAlert';

import TableEstorno from '../../../../components/Table';
import BoxShadow from '../../../../components/BoxShadow';
import Button from '../../../../components/Button';
import ReversalService, {
  IReversalServiceSearch,
} from '../../../../services/reversal/ReversalService';
import SuggestionsServices from '../../../../services/sugestions/SugestionsService';
import Loading from '../../../../components/Loading';

interface IReversalColumn {
  id:
    | 'transactionNumber'
    | 'reversedAtFormatted'
    | 'paidAtFormatted'
    | 'status'
    | 'application'
    | 'totalAmountFormatted'
    | 'login'
    | 'email'
    | 'salesOrganization'
    | 'createdBy';
  label: string;
  minWidth?: string;
  align?: 'right';
}

const columns: IReversalColumn[] = [
  { id: 'transactionNumber', label: 'Transaction ID' },
  { id: 'paidAtFormatted', label: 'Paid At' },
  { id: 'reversedAtFormatted', label: 'Voided At' },
  { id: 'status', label: 'STATUS' },
  { id: 'application', label: 'Application' },
  { id: 'totalAmountFormatted', label: 'Total' },
  { id: 'login', label: 'User Login' },
  { id: 'email', label: 'User E-mail' },
  { id: 'salesOrganization', label: 'Site' },
  { id: 'createdBy', label: 'Reversed By' },
];

type IApplicationsSuggestions = {
  application: string;
};

type ISalesOrganizationSuggestions = {
  salesOrganization: string;
};

const Reversal = (): JSX.Element => {
  const { t } = useTranslation('reversal');

  const initialPage = parseInt(process.env.REACT_APP_INITIAL_PAGE || '0', 10);
  const initialRowsPerPage = parseInt(
    process.env.REACT_APP_ROWS_PER_PAGE || '10',
    10,
  );

  const { enqueueSnackbar } = useSnackbar();

  const [reversals, setReversals] = useState([]);

  const [suggestionsSalesOrganization, setSuggestionsSalesOrganization] =
    useState<ISalesOrganizationSuggestions[]>([]);
  const [suggestionsApplications, setSuggestionsApplications] = useState<
    IApplicationsSuggestions[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [transactionID, setTransactionID] = useState('');
  const [applicationState, setApplicationState] = useState('');
  const [salesOrganizationState, setSalesOrganizationState] = useState('');
  const [openDialogReversal, setOpenDialogReversal] = useState(false);
  const [inputMoney, setInputMoney] = useState('');
  const [transactionCode, setTransactionCode] = useState('');
  const [pageState, setPageState] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalCreated, setTotalCreated] = useState(0);

  const handleChangeSalesOrganization = (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setSalesOrganizationState(event.target.value);
  };

  const handleChangeApplications = (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setApplicationState(event.target.value);
  };

  const fetchReversalData = (obj: IReversalServiceSearch) => {
    const { application, salesOrganization, transactionNumber, page, limit } =
      obj;
    ReversalService.search({
      application,
      transactionNumber,
      salesOrganization,
      page,
      limit,
    }).then((r) => {
      setReversals(r.data.body.reversals);
      setTotalCreated(parseInt(r.data.body.reversalNumbers.totalCreated, 10));
      setLoading(false);
    });
  };

  const handleRowsPerPageChange = (rowsPerPageChange: number) => {
    setRowsPerPage(rowsPerPageChange);
    fetchReversalData({
      application: applicationState,
      salesOrganization: salesOrganizationState,
      transactionNumber: transactionID,
      limit: rowsPerPageChange,
      page: pageState,
    });
  };

  const handlePageChange = (pageChange: number) => {
    setPageState(pageChange);
    fetchReversalData({
      application: applicationState,
      salesOrganization: salesOrganizationState,
      transactionNumber: transactionID,
      limit: rowsPerPage,
      page: pageChange,
    });
  };

  const handleClickShowDialog = () => {
    if (!openDialogReversal === false) {
      setInputMoney('');
      setTransactionCode('');
    }

    setOpenDialogReversal(!openDialogReversal);
  };

  const handleSubmitFilters = () => {
    setPageState(initialPage);
    fetchReversalData({
      application: applicationState,
      salesOrganization: salesOrganizationState,
      transactionNumber: transactionID,
      limit: rowsPerPage,
      page: initialPage,
    });
  };

  const handleSubmitReversal = async () => {
    if (!transactionCode) {
      enqueueSnackbar(t('Enter the transaction code'), { variant: 'error' });
      return;
    }

    try {
      const createReversal = await ReversalService.createReversal({
        transactionNumber: transactionCode,
        value: inputMoney,
        typeReversal: '2', // 1 - partial | 2 - total
      });

      const created = createReversal.data.body;
      const newReversal: any = [created, ...reversals];

      setReversals(newReversal);
      enqueueSnackbar(t('Chargeback successfully made!'), {
        variant: 'success',
      });
      setInputMoney('');
      setTransactionCode('');
      setOpenDialogReversal(false);
    } catch (error) {
      const msgError = error.response.data.message
        ? error.response.data.message
        : t('Request Error');
      enqueueSnackbar(t('Error', { error: msgError }), { variant: 'error' });
    }
  };

  const handleClearFilters = () => {
    setTransactionID('');
    setApplicationState('');
    setSalesOrganizationState('');
    fetchReversalData({});
  };

  useEffect(() => {
    fetchReversalData({
      page: parseInt(process.env.REACT_APP_INITIAL_PAGE || '0', 10),
      limit: parseInt(process.env.REACT_APP_ROWS_PER_PAGE || '10', 10),
    });

    SuggestionsServices.getSuggestionsReversal().then((response) => {
      setSuggestionsApplications(response.data.body.applications);
      setSuggestionsSalesOrganization(response.data.body.salesOrganizations);
    });
  }, []);

  return (
    <ContainerPage>
      <Grid container>
        <Grid item xs={12}>
          <MsgAlert
            className="alert"
            title={t('Attention!')}
            message={t('Reversal Rules')}
          />
        </Grid>
      </Grid>
      <Grid container className="mt-16">
        <Grid item xs={12}>
          <BoxShadow>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} lg={2} xl={3}>
                <TextField
                  onChange={(input) => setTransactionID(input.target.value)}
                  type="text"
                  value={transactionID}
                  placeholder={t('Transaction Number')}
                  label={t('Transaction Number')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={2} xl={2}>
                <FormControl fullWidth>
                  <InputLabel
                    id="select-sales-organization"
                    className="input-label"
                  >
                    {t('Sales Organization')}
                  </InputLabel>
                  <Select
                    labelId="select-sales-organization"
                    id="select-sales-organization-input"
                    value={salesOrganizationState}
                    onChange={handleChangeSalesOrganization}
                  >
                    {suggestionsSalesOrganization.map((item) => (
                      <MenuItem
                        key={item.salesOrganization}
                        value={item.salesOrganization}
                      >
                        {item.salesOrganization.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2} xl={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-applications" className="input-label">
                    {t('Application')}
                  </InputLabel>
                  <Select
                    labelId="select-applications"
                    id="select-applications-input"
                    value={applicationState}
                    onChange={handleChangeApplications}
                  >
                    {suggestionsApplications.map((item) => (
                      <MenuItem key={item.application} value={item.application}>
                        {item.application.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2} xl={2}>
                <Button
                  text={t('Search')}
                  type="button"
                  onClick={handleSubmitFilters}
                  className="estorno"
                />
              </Grid>
              <Grid item xs={12} lg={2} xl={2}>
                <Button
                  text={t('Clear')}
                  type="button"
                  onClick={handleClearFilters}
                  className="cancel"
                />
              </Grid>
            </Grid>
          </BoxShadow>
        </Grid>
      </Grid>
      <Grid container className="mt-16">
        <Grid item xs={12}>
          <BoxShadow>
            {loading ? (
              <Loading />
            ) : (
              <TableEstorno
                rows={reversals}
                columns={columns}
                title={t('Chargebacks Made')}
                showBtn
                handleClickShowDialog={handleClickShowDialog}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                totalRows={totalCreated}
                rowsPerPage={rowsPerPage}
                page={pageState}
                buttonTitle={t('New Chargeback')}
                t={t}
              />
            )}
          </BoxShadow>
        </Grid>
      </Grid>

      <Dialog
        open={openDialogReversal}
        onClose={handleClickShowDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Enter Chargeback Data')}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextInputTransactionCode
                id="input-transaction-code-reversal"
                className="input-transaction-code-reversal"
                label={t('Transaction Number')}
                value={transactionCode}
                onChange={(input) => setTransactionCode(input.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={12} sm={3}>
              <Button
                text={t('Cancel')}
                className="cancel"
                type="button"
                onClick={handleClickShowDialog}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                text={t('Make Chargeback')}
                className="estorno"
                type="button"
                onClick={handleSubmitReversal}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </ContainerPage>
  );
};

export default Reversal;
