import { AxiosResponse } from 'axios';
import api from '../api';

const uri = '/report/checkout';

export type ICheckoutReportSearchProps = {
  application?: string;
  transactionNumber?: string;
  status?: string;
  salesOrganization?: string;
  initialCreation?: string;
  finalCreation?: string;
  page?: number;
  limit?: number;
};

const CheckoutReportService = {
  search: async ({
    application = '',
    transactionNumber = '',
    status = '',
    salesOrganization = '',
    initialCreation = '',
    finalCreation = '',
    page = 0,
    limit = 10,
  }: ICheckoutReportSearchProps): Promise<AxiosResponse> => {
    const currPage = page + 1;
    let qs = '';
    if (application) qs += `&application=${application}`;
    if (transactionNumber) qs += `&transactionNumber=${transactionNumber}`;
    if (salesOrganization) qs += `&salesOrganization=${salesOrganization}`;
    if (initialCreation) qs += `&initialCreation=${initialCreation}`;
    if (finalCreation) qs += `&finalCreation=${finalCreation}`;
    if (status) qs += `&status=${status}`;
    if (typeof page === 'number') qs += `&page=${currPage}`;
    if (limit) qs += `&limit=${limit}`;
    if (qs) qs = `/?${qs.slice(1)}`;
    return api.get(`${uri}${qs}`);
  },

  download: async ({
    application = '',
    transactionNumber = '',
    status = '',
    salesOrganization = '',
    initialCreation = '',
    finalCreation = '',
  }: ICheckoutReportSearchProps): Promise<AxiosResponse> => {
    let qs = '';
    if (application) qs += `&application=${application}`;
    if (transactionNumber) qs += `&transactionNumber=${transactionNumber}`;
    if (salesOrganization) qs += `&salesOrganization=${salesOrganization}`;
    if (initialCreation) qs += `&initialCreation=${initialCreation}`;
    if (finalCreation) qs += `&finalCreation=${finalCreation}`;
    if (status) qs += `&status=${status}`;
    if (qs) qs = `/?${qs.slice(1)}`;
    return api.get(`${uri}/download${qs}`, { responseType: 'blob' });
  },
};

export default CheckoutReportService;
