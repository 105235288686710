import styled from 'styled-components';

export const CustomerUi = styled.div`
  h2 {
    font-size: 16px;
    color: #3f3f3c;
    font-weight: 400;
    text-transform: uppercase;
  }
`;
