import React from 'react';

import { CardUi } from './style';

type ICardsProps = {
  value: string;
  text: string;
  className: 'blue-light' | 'yellow' | 'red' | 'blue-dark';
};

const Cards = ({ value, text, className }: ICardsProps): JSX.Element => {
  return (
    <CardUi>
      <div className={`card ${className}`}>
        <h3>{value}</h3>
        <p>{text}</p>
      </div>
    </CardUi>
  );
};

export default Cards;
