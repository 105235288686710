import styled from 'styled-components';

export const CardUi = styled.div`
  display: block;

  .card {
    padding: 16px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

    h3 {
      font-size: 32px;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
    }
  }

  .blue-light {
    background-color: #82afdd;

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .yellow {
    background-color: #d89b00;

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .red {
    background-color: #d80000;

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .blue-dark {
    background-color: #100690;

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }
`;
