import React from 'react';
// import { MdContentCopy } from 'react-icons/md';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Grid, Box } from '@material-ui/core';
import { PaymentOrderInfoUI } from './style';

export interface PaymentOrderInfoProps {
  paymentOrderNumber: string;
}

const PaymentOrderInfo = ({
  paymentOrderNumber,
}: PaymentOrderInfoProps): JSX.Element => {
  // const handleCopy = () => {
  //   navigator.clipboard.writeText(paymentOrderNumber);
  //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //   const copiedEl = document.getElementById('copied')!;
  //   copiedEl.style.display = 'block';
  //   setTimeout(() => {
  //     copiedEl.style.display = 'none';
  //   }, 2000);
  // };

  return (
    <PaymentOrderInfoUI>
      <Grid container className="payment-order-data">
        <Grid item xs={2} style={{ width: '90px', whiteSpace: 'nowrap' }}>
          <Box
            fontWeight="fontWeightBold"
            style={{ color: '#45BF88', display: 'flex', flexDirection: 'row' }}
          >
            {`Transaction ID: ${paymentOrderNumber}`}
          </Box>
        </Grid>
        {/* <Grid item xs={3} className="display-flex">
          <MdContentCopy className="icon click" onClick={handleCopy} />
          <p id="copied">Copied!</p>
        </Grid>
        <Grid item /> */}
        {/* <Grid item xs={2} className="display-flex">
          <AccessTimeIcon className="icon" />
          <Box fontWeight="fontWeightBold">Order expiration:</Box>
        </Grid>
        <Grid item>
          <Box color="#FF5744" marginLeft={1}>
            24 hours
          </Box>
        </Grid> */}
      </Grid>
    </PaymentOrderInfoUI>
  );
};

export default PaymentOrderInfo;
