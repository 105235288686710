import styled from 'styled-components';
import Container from '@material-ui/core/Container';

export const ContainerUiBody = styled(Container)`
  padding-bottom: 64px;
  max-width: 1024px;
  scroll-behavior: smooth;

  .arrow-back {
    padding: 26px 0;
    text-decoration: none;
    font-size: 12px;
    color: #3f3f3c;

    button {
      border: none;
      background: none;
      cursor: pointer;
      display: inline-flex;
      align-items: center;

      & > svg {
        margin-right: 3px;
        font-size: 16px;
      }
    }
  }
`;
