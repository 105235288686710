import { AxiosResponse } from 'axios';
import api from '../api';
import { FindBankInformationDTO } from './dtos/find-bank-info-dto';
import { BankInformationDTO } from './dtos/bank-info-dto';

type IFindAllProps = {
  page?: number;
  limit?: number;
};

const BankingService = {
  baseUri: '/banking',

  async findBankInformation(
    obj: FindBankInformationDTO,
  ): Promise<AxiosResponse> {
    const { currency, salesOrganization, type } = obj;
    return api.get(
      `${BankingService.baseUri}/${currency}/${salesOrganization}/${type
        .split('_')
        .join(' ')}`,
    );
  },

  async findAll({
    page = 1,
    limit = 10,
  }: IFindAllProps): Promise<AxiosResponse> {
    const queryParams = `?page=${page}&limit=${limit}`;
    return api.get(`${BankingService.baseUri}/find-all${queryParams}`);
  },

  async create(bankInfo: BankInformationDTO): Promise<AxiosResponse> {
    const sendBankInfo = {
      ...bankInfo,
      bankDestination: 'A',
      type: 'WIRE TRANSFER',
    };

    return api.post(`${BankingService.baseUri}/`, sendBankInfo);
  },

  async update(bankInfo: BankInformationDTO): Promise<AxiosResponse> {
    const { id } = bankInfo;

    return api.put(`${BankingService.baseUri}/${id}`, bankInfo);
  },

  async delete(id: string): Promise<AxiosResponse> {
    return api.delete(`${BankingService.baseUri}/${id}`);
  },
};

export default BankingService;
