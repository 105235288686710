import axios, { AxiosRequestConfig } from 'axios';
import localStorageService from './localStorage';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_EPAYMENTS_API}`,
});

api.interceptors.request.use(
  (config: AxiosRequestConfig): any => {
    const token = localStorageService.getAccessToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.headers['Accept-Language'] = window.navigator.language || 'en-US';
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err.request.responseType === 'blob' &&
      err.response.data instanceof Blob &&
      err.response.data?.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          err.response.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(err));
        };
        reader.onerror = () => {
          reject(err);
        };
        reader.readAsText(err.response.data);
      });
    }
    if (err.response && err.response.status === 401) {
      sessionStorage.clear();
      window.location.href = `${process.env.REACT_APP_PORTAL_EMBRAER}`;
    }
    throw err;
  },
);

export default api;
