// eslint-disable-next-line no-shadow
export enum PaymentMethods {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
  TICKET = 'TICKET',
}

export default interface ICheckoutPaymentDTO {
  checkoutID: string;
  cardCode: string;
  cardNumber?: string;
  cardExpiration?: string;
  cardHolderName?: string;
  userProfile?: string;
  userPaymentProfile?: string;
  installment?: number;
}

export interface BillingInformation {
  firstName: string;
  documentNumber: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  number?: string;
  phoneNumber?: string;
  complement?: string;
  update?: boolean;
}

export interface ICheckoutCreditCardPayment {
  checkoutID: string;
  cardCode: string;
  cardNumber: string;
  cardExpiration: string;
  cardHolderName: string;
  cardEncryptionKey?: string;
  userProfile?: string;
  userPaymentProfile?: string;
  installment: number;
  billingInformation?: BillingInformation;
}

export interface ICheckoutTicketPayment {
  checkoutID: string;
}
export interface ICheckoutTokenPayment {
  checkoutID: string;
  cardCode: string;
  userProfile?: string;
  userPaymentProfile?: string;
}

// export type CompanyBankInformationType =

export type PaymentOrderDTO = {
  checkoutId: string;
};
export type PaymentCheckoutDTO = {
  checkoutId: string;
  status: string;
};

export type WireTransferPaymentDTO = {
  checkoutId: string;
  transactionNumber: string;
};
