import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import Loading from '../../components/Loading/index';

interface IParamsRoute {
  hash: string;
}

const AuthenticatedUser = (): JSX.Element => {
  const { hash } = useParams<IParamsRoute>();
  const { setTokenAuth } = useAuth();
  const loading = true;

  useEffect(() => {
    setTokenAuth(hash);
  }, [hash, setTokenAuth]);

  return loading ? <Loading /> : <h1>Error</h1>;
};
export default AuthenticatedUser;
