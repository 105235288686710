import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';

import DialogContentText from '@material-ui/core/DialogContentText';
import ButtonMaterialUi from '@material-ui/core/Button';
import gatewayService from '../../../../services/gateways';

import { ContainerGateways } from './style';
import BoxShadow from '../../../../components/BoxShadow';
import TableData from '../../../../components/Table';
import MenuButton from '../../../../components/MenuButton/index';
import Button from '../../../../components/Button';

interface IColumn {
  id: string;
  label: string;
  minWidth?: string | undefined;
  maxWidth?: string | undefined;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}

export type IGatewayProps = {
  createdAt?: string;
  createdAtFormatted?: string;
  class: string;
  id: string;
  title: string;
  updatedAt?: string;
};

export default (): JSX.Element => {
  const snackbar = useSnackbar();
  const [listGateways, setListGateways] = useState<IGatewayProps[]>([]);
  const [totalRowsList, setTotalRowsList] = useState(0);
  const [openDialogGateway, setOpenDialogGateway] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [pageState, setPage] = useState(0);
  const [totalPerPageState, setTotalPerPageState] = useState(10);
  const [titleGateway, setTitleGateway] = useState('');
  const [titleClass, setTitleClass] = useState('');
  const [typeAction, setTypeAction] = useState('');
  const [findOneGatewayState, setFindOneGatewayState] = useState(
    {} as IGatewayProps,
  );

  const columns: IColumn[] = [
    { id: 'id', label: 'ID', align: 'left', maxWidth: '25%' },
    { id: 'title', label: 'Name', align: 'left' },
    { id: 'class', label: 'Class Name', align: 'left' },
    { id: 'createdAtFormatted', label: 'Created At', align: 'left' },
    { id: 'action', label: 'Action', align: 'center' },
  ];

  const loadChangeListGateways = (limit: number, page: number) => {
    gatewayService.findAll({ limit, page: page + 1 }).then((response) => {
      setListGateways(response.data.body.gateways);
      setTotalRowsList(response.data.body.total);
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    gatewayService
      .findAll({ limit: totalPerPageState, page: page + 1 })
      .then((response) => {
        setListGateways(response.data.body.gateways);
        setTotalRowsList(response.data.body.total);
      });
  };

  const handleDelete = (gateway: IGatewayProps) => {
    setFindOneGatewayState(gateway);
    setOpenDialogDelete(!openDialogDelete);
  };

  const handleCloseDialogDelete = (statusDialog: boolean) => {
    if (statusDialog === false) {
      setFindOneGatewayState({} as IGatewayProps);
    }

    setOpenDialogDelete(statusDialog);
  };

  const handleConfirmDeleteGateway = () => {
    try {
      gatewayService.deleteGateway(findOneGatewayState.id);
      snackbar.enqueueSnackbar('Gateway deletado com sucesso.', {
        variant: 'success',
      });

      const newListGateways = listGateways.filter(
        (gateway) => gateway.id !== findOneGatewayState.id,
      );

      setListGateways(newListGateways);

      setTotalRowsList(totalRowsList - 1);
    } catch (error) {
      snackbar.enqueueSnackbar('Erro ao deletar o gateway', {
        variant: 'error',
      });
    }
    setFindOneGatewayState({} as IGatewayProps);
    setOpenDialogDelete(!openDialogDelete);
  };

  const handleEdit = (gateway: IGatewayProps) => {
    setFindOneGatewayState(gateway);
    setTitleClass(gateway.class);
    setTitleGateway(gateway.title);

    setTypeAction('edit');
    setOpenDialogGateway(!openDialogGateway);
  };

  const getRowsPerPage = (rowsPerPage: number) => {
    setTotalPerPageState(rowsPerPage);
    loadChangeListGateways(rowsPerPage, pageState);
  };

  const mapMenuButton = (objs: IGatewayProps[]) =>
    objs.map((e: IGatewayProps) => ({
      ...e,
      action: (
        <MenuButton
          id={e.id as string}
          handleDelete={() => handleDelete(e)}
          handleEdit={() => handleEdit(e)}
          title="Ações"
        />
      ),
    }));

  const handleShowDialogGateways = async () => {
    if (!openDialogGateway === false) {
      setTitleClass('');
      setTitleGateway('');
      setTypeAction('');
    }

    setOpenDialogGateway(!openDialogGateway);
  };

  const handleShowDialog = () => {
    setTypeAction('save');
    setOpenDialogGateway(!openDialogGateway);
  };

  // eslint-disable-next-line consistent-return
  const handleSubmitGateway = async (): Promise<null | undefined | void> => {
    if (titleGateway.length < 3) {
      snackbar.enqueueSnackbar('Informe Nome do Gateway', {
        variant: 'warning',
      });
      return null;
    }

    if (titleClass.length < 3) {
      snackbar.enqueueSnackbar('Informe Nome da Class', { variant: 'warning' });
      return null;
    }

    try {
      const newGateway = await gatewayService.saveNewGateway({
        title: titleGateway,
        nameClass: titleClass,
      });

      const { body } = newGateway.data;

      const newList: any = [body, ...listGateways];

      setListGateways(newList);
      setTitleClass('');
      setTitleGateway('');
      setTypeAction('');
      snackbar.enqueueSnackbar('Novo gateway salvo com sucesso!', {
        variant: 'success',
      });
      setOpenDialogGateway(!openDialogGateway);
      setTotalRowsList(totalRowsList + 1);
    } catch (error) {
      snackbar.enqueueSnackbar('Erro ao salvar novo gateway', {
        variant: 'error',
      });
      setTitleClass('');
      setTitleGateway('');
      setTypeAction('');
    }
  };

  const handleSubmitGatewayUpdate = async (): Promise<
    null | undefined | void
    // eslint-disable-next-line consistent-return
  > => {
    if (titleGateway.length < 3) {
      snackbar.enqueueSnackbar('Informe Nome do Gateway', {
        variant: 'warning',
      });
      return null;
    }

    if (titleClass.length < 3) {
      snackbar.enqueueSnackbar('Informe Nome da Class', { variant: 'warning' });
      return null;
    }

    try {
      const newGateway = await gatewayService.updateGateway({
        title: titleGateway,
        nameClass: titleClass,
        id: findOneGatewayState.id,
      });

      const { body } = newGateway.data;

      const newListMap: IGatewayProps[] = listGateways.map(
        (gateway: IGatewayProps) => {
          if (gateway.id === body.id) return body;
          return gateway;
        },
      );

      setListGateways(newListMap);
      setTitleClass('');
      setTitleGateway('');
      setTypeAction('');
      snackbar.enqueueSnackbar('Gateway atualizado com sucesso!', {
        variant: 'success',
      });
      setOpenDialogGateway(!openDialogGateway);
      setTotalRowsList(totalRowsList + 1);
    } catch (error) {
      snackbar.enqueueSnackbar('Erro ao atualizar o gateway', {
        variant: 'error',
      });
      setTitleClass('');
      setTitleGateway('');
      setTypeAction('');
    }
  };

  useEffect(() => {
    gatewayService.findAll({}).then((response) => {
      setListGateways(response.data.body.gateways);
      setTotalRowsList(response.data.body.total);
    });
  }, []);

  return (
    <ContainerGateways>
      <BoxShadow>
        <TableData
          rows={mapMenuButton(listGateways)}
          columns={columns}
          title="Lista Gateways"
          showBtn
          totalRows={totalRowsList}
          handleRowsPerPageChange={getRowsPerPage}
          page={pageState}
          handlePageChange={handlePageChange}
          rowsPerPage={totalPerPageState}
          handleClickShowDialog={handleShowDialog}
          buttonTitle="Novo Gateway"
        />
      </BoxShadow>

      <Dialog
        open={openDialogGateway}
        onClose={handleShowDialogGateways}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Informe os dados do novo Gateway
        </DialogTitle>
        <DialogContent>
          <Grid container style={{ marginBottom: '32px' }}>
            <Grid item xs={12}>
              <TextField
                name="title-gateway"
                label="Nome do gateway"
                value={titleGateway}
                className="input-admin-custom"
                onChange={(input) => setTitleGateway(input.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                name="title-class"
                label="Nome da Classe"
                value={titleClass}
                className="input-admin-custom"
                onChange={(input) => setTitleClass(input.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="flex-end" style={{ padding: '16px' }}>
            <Grid item xs={12} sm={4} style={{ marginRight: '16px' }}>
              <Button
                onClick={handleShowDialogGateways}
                text="Cancelar"
                className="cancel"
                type="button"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                onClick={
                  typeAction === 'save'
                    ? handleSubmitGateway
                    : handleSubmitGatewayUpdate
                }
                text="Salvar"
                className="summary-confirm-active"
                type="button"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogDelete}
        onClose={() => handleCloseDialogDelete(!openDialogDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tem certeza que deseja deletar o Gateway?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao deletar um gateway todas as configurações serão deletadas, e o
            pagamento para esse gateway não está mais em funcionamento.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonMaterialUi
            onClick={() => handleCloseDialogDelete(!openDialogDelete)}
            color="primary"
          >
            Cancelar
          </ButtonMaterialUi>
          <ButtonMaterialUi
            onClick={handleConfirmDeleteGateway}
            color="primary"
            autoFocus
          >
            Sim, deletar!
          </ButtonMaterialUi>
        </DialogActions>
      </Dialog>
    </ContainerGateways>
  );
};
