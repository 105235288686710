import React from 'react';
import { Grid, InputAdornment, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { PaymentConfirmationUi, TextInputCVV } from './style';
import Button from '../../Button';
import cardcode from '../../../assets/images/cardcode.png';
import { usePayment } from '../../../hooks/payment';
import IDocument from '../../../services/checkout/interfaces/IDocumentDTO';

export interface IPaymentComponentProps {
  maxInstallments: number;
  gateway: string;
  documents: IDocument[];
}

const Payment = ({
  maxInstallments,
  gateway,
  documents,
}: IPaymentComponentProps): JSX.Element => {
  const { t } = useTranslation('checkout');
  const installments = [...new Array(maxInstallments)]
    .map((_, i) => i + 1)
    .sort((a, b) => a - b);

  const {
    cvv,
    setCvv,
    btnConfirm,
    handleSubmitPayment,
    installment,
    setInstallment,
  } = usePayment();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInstallmentChange = (e: React.ChangeEvent<{ value: any }>) => {
    const installmentIsAllowed = documents.filter(
      (document) =>
        document.installment !== '001' && document.salesOrganization !== 'BOT1',
    );

    if (installmentIsAllowed.length > 0) {
      Swal.fire({
        title: 'Alerta!',
        html: `Você selecionou faturas já parceladas que não podem ser reparceladas no cartão de crédito. <br />
        <br />Gentileza removê-las do checkout ou selecionar outra forma de pagamento. <br />
        Veja os "Detalhes" na lista abaixo.`,
        icon: 'warning',
        allowOutsideClick: true,
        allowEscapeKey: true,
      });

      return;
    }
    setInstallment(+e.target.value);
  };

  const handleSubmit = () => {
    handleSubmitPayment();
  };

  return (
    <PaymentConfirmationUi>
      <Grid container className="paper">
        {['CieloGateway', 'GetnetGateway'].includes(gateway) &&
        installments.length > 1 ? (
          <Grid item xs={12} md={6} className="mb-25">
            <Grid item xs={12}>
              <h4>{t('Select the number of installments')}</h4>
            </Grid>
            <Grid item xs={12}>
              <Select
                labelId="select-installment"
                id="select-installment"
                value={installment}
                onChange={handleInstallmentChange}
                className="select-installment"
              >
                {installments.map((inst) => (
                  <MenuItem key={inst} value={inst}>
                    {inst}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12} md={6} className="mb-25">
          <Grid item xs={12}>
            <h4>{t('Insert CVV code')}</h4>
          </Grid>
          <Grid item xs={12}>
            <TextInputCVV
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
              placeholder="CVV"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={cardcode} alt="card-code" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <p>
            {t(
              `Person is hereby made aware that the electronic payment transaction will be made by a third-party payment gateway through the internet and for this reason, no credit or debit card, as applicable data or information will be stored at Embraer’s database.`,
            )}
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={10} />
        <Grid item xs={12} md={2}>
          <Button
            disabled={btnConfirm}
            text={t('Confirm Payment')}
            className={
              btnConfirm ? 'summary-confirm-disabled' : 'summary-confirm-active'
            }
            type="button"
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </PaymentConfirmationUi>
  );
};

export default Payment;
