/* eslint-disable camelcase */
/* eslint-disable import/no-named-default */
import React from 'react';
import { RiBankCard2Line } from 'react-icons/ri';
import {
  default as american_express,
  default as americanexpress,
} from '../../../assets/images/card-brands/americanexpress.png';
import amex from '../../../assets/images/card-brands/amex.png';
import aura from '../../../assets/images/card-brands/aura.png';
import dinersclub from '../../../assets/images/card-brands/dinersclub.png';
import discover from '../../../assets/images/card-brands/discover.png';
import elo from '../../../assets/images/card-brands/elo.png';
import hipercard from '../../../assets/images/card-brands/hipercard.png';
import jcb from '../../../assets/images/card-brands/jcb.png';
import mastercard from '../../../assets/images/card-brands/mastercard.png';
import visa from '../../../assets/images/card-brands/visa.png';

import { FlagsUI } from './style';

const flags: { [key: string]: any } = {
  visa,
  mastercard,
  americanexpress,
  american_express,
  amex,
  elo,
  dinersclub,
  hipercard,
  discover,
  jcb,
  aura,
};
interface IFlagsProps {
  show?: boolean;
  cardBrands?: string[];
}

const Flags: React.FC<IFlagsProps> = ({ show = false, cardBrands = [] }) => {
  if (!show) return null;

  const flagList = cardBrands.map((e) => {
    const brand = e.toLowerCase();
    if (!flags[brand]) return <RiBankCard2Line color="#94BFF3" size={18} />;
    return <img key={e} src={flags[brand]} alt="Flags" />;
  });

  return <FlagsUI>{flagList}</FlagsUI>;
};

export default Flags;
