import styled from 'styled-components';

export const ContainerMsgAlert = styled.div`
  .alert {
    background-color: #f4ffb1;
    padding: 16px;

    h3 {
      font-size: 24px;
      color: #ff6666;
    }

    p {
      font-size: 14px;
      margin-top: 14px;
    }
  }
`;
