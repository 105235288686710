import styled from 'styled-components';

export const AdministratorContainer = styled.div`
  padding-top: 80px;

  .MuiAppBar-colorDefault {
    background: transparent;
  }

  .MuiPaper-elevation4 {
    box-shadow: none;
    border-bottom: 1px solid #ccc;
  }
`;
