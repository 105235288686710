import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import Swal from 'sweetalert2';
import { Box, Button, Grid } from '@material-ui/core';
// import { createFileLevelUniqueName } from 'typescript';
import { AttachReceiptUI } from './style';

export interface AttachRecipeProps {
  setSelectedFile: (obj: FormData) => void;
}

const AttachReceipt = ({ setSelectedFile }: AttachRecipeProps): JSX.Element => {
  const { t } = useTranslation('checkout');

  const [fileName, setFileName] = useState<string | undefined>(undefined);

  const handleAttach = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const verifyFileFormat = e.target.files[0].name.split('.');

    if (
      verifyFileFormat.slice(-1)[0].toLowerCase() !== 'jpg' &&
      verifyFileFormat.slice(-1)[0].toLowerCase() !== 'jpeg' &&
      verifyFileFormat.slice(-1)[0].toLowerCase() !== 'png' &&
      verifyFileFormat.slice(-1)[0].toLowerCase() !== 'pdf'
    ) {
      Swal.fire({
        title: t('Invalid file format'),
        text: t('Please, select a file in jpg, jpeg, png or pdf.'),
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      return;
    }

    if (e.target.files[0].size > 5 * 1024 * 1024) {
      Swal.fire({
        title: t('Invalid file size'),
        text: t('Please, select a file with a maximum size of 5mb.'),
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      return;
    }

    setFileName(e.target.files[0].name);

    const formData = new FormData();
    formData.append('voucher', e.target.files[0]);
    setSelectedFile(formData);
  };

  return (
    <AttachReceiptUI>
      <Grid className="attach-content">
        {/* <Grid item xs={12} className="attach-recipe-header">
          <ReceiptOutlinedIcon className="icon" />
          <Box fontWeight="bold">Choose file</Box>
        </Grid> */}
        <Grid>
          <Button
            variant="outlined"
            component="label"
            className="choose-file-button"
          >
            {t('Choose file')}
            <input type="file" hidden onChange={handleAttach} />
          </Button>
        </Grid>
        <Grid className="chosen-file">
          <Box marginLeft="8px">{fileName || t('No file chosen')}</Box>
        </Grid>
      </Grid>
    </AttachReceiptUI>
  );
};

export default AttachReceipt;
