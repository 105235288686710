import styled from 'styled-components';

export const HeaderUiAdmin = styled.header`
  width: 100%;
  .logo-header {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      color: #898989;
    }
  }

  .user-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    h4 {
      font-size: 16px;
      line-height: normal;
      color: #666666;
      font-weight: 700;
    }

    h5 {
      font-size: 12px;
      line-height: normal;
      color: #666666;
      font-weight: normal;
    }
  }

  @media only screen and (max-width: 1279px) {
    .logo-header {
      .menu-responsivo {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 959px) {
    .user-area {
      display: none;
    }
  }
`;
