import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

export interface IMenuButton {
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  id: string;
  title: string;
}

export default ({
  handleEdit,
  handleDelete,
  id,
  title,
}: IMenuButton): JSX.Element => {
  const { t } = useTranslation('globals');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line no-shadow
  const handleAction = (type: string, id: string) => {
    setAnchorEl(null);
    if (type === 'edit') handleEdit(id);
    if (type === 'delete') handleDelete(id);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleAction('edit', id)}>
          {t('Edit')}
        </MenuItem>
        <MenuItem onClick={() => handleAction('delete', id)}>
          {t('Delete')}
        </MenuItem>
      </Menu>
    </div>
  );
};
