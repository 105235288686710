import styled from 'styled-components';

export const ContainerPage = styled.div`
  padding-top: 80px;

  .MuiFormControl-marginNormal {
    width: 100%;

    .MuiFormLabel-root.Mui-focused {
      color: rgba(0, 0, 0, 0.54);
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #cdcdcd;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid #cdcdcd;
    }

    .MuiInput-underline:after {
      border-bottom: 1px solid #82afdd;
    }
  }

  .MuiInputBase-input {
    padding: 11px 8px;
  }

  .style-select {
    width: 100%;
    padding: 16px;

    .input-label {
      margin-top: 16px;
      margin-left: 16px;
    }
  }

  .date-picker-inicial {
    padding-left: 25px;
  }

  .date-picker-final {
    padding-left: 25px;
  }
`;

export const TitleBox = styled.h2`
  font-size: 24px;
  color: #9a9a9a;
  font-weight: 400;
`;
