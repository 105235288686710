import styled from 'styled-components';

export const ButtonUi = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  .back {
    display: block;
    text-align: center;
    background-color: #bbb;
    border: 0;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    outline: none;
  }

  .summary-confirm-disabled {
    display: block;
    text-align: center;
    background-color: #c4c4c4;
    border: 0;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    cursor: default;
    outline: none;
  }

  .summary-confirm-active {
    display: block;
    text-align: center;
    background-color: #3ab17f;
    border: 0;
    width: 100%;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s;
    outline: none;

    &:hover {
      background-color: #1e9a66;
    }
    &:focus {
      background-color: #095e3a;
    }
  }

  .estorno {
    display: block;
    text-align: center;
    background-color: #3ab17f;
    border: 0;
    width: 100%;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s;
    outline: none;

    &:hover {
      background-color: #095e3a;
    }
  }

  .search-report {
    display: block;
    text-align: center;
    background-color: #100690;
    border: 0;
    width: 100%;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s;
    outline: none;

    &:hover {
      background-color: #0c0650;
    }
  }

  .cancel {
    display: block;
    text-align: center;
    background-color: #d89b00;
    border: 0;
    width: 100%;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s;
    outline: none;

    &:hover {
      background-color: #af7f02;
    }
  }

  .new-card {
    display: block;
    text-align: center;
    background-color: #28835c;
    border: 0;
    width: 100%;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); */
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    margin-top: 10px;
  }

  .tcs {
    width: 100%;
    padding: 12px 16px;
    border: none;
    cursor: pointer;
    background-color: #669c0f;
    font-size: 14px;
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    outline: none;
    transition: background-color 0.3s;

    &:hover {
      background-color: #355600;
    }

    &:disabled {
      background-color: #ccc;
    }
  }

  .wire-transfer {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    background-color: #28835c;
    border: 0;
    width: 100%;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    height: 100%;

    &:hover {
      background-color: #1e9a66;
    }
    &:focus {
      background-color: #095e3a;
    }
  }

  .bank-info-cancel {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    background-color: #b0b0af;
    border: 0;
    width: 100%;
    padding: 8px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    height: 100%;

    &:hover {
      background-color: #aaaaaa;
    }

    &:focus {
      background-color: #999999;
    }
  }

  .send-receipt-button {
    display: block;
    text-align: center;
    background-color: #3f48cc;
    border: 0;
    width: auto;
    height: auto;
    padding: 6px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #2d359e;
    }

    &:disabled {
      background-color: #ccc;
      color: #7f7f7f;
    }
  }

  .send-receipt-cancel-button {
    display: block;
    text-align: center;
    background-color: #c3c3c3;
    border: 0;
    width: auto;
    height: auto;
    padding: 6px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #3e4042;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #9b9999;
    }

    &:disabled {
      background-color: #ccc;
      color: #7f7f7f;
    }
  }

  .confirm-button {
    display: block;
    text-align: center;
    background-color: #3f48cc;
    border: 0;
    width: auto;
    height: auto;
    padding: 6px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #2d359e;
    }

    &:disabled {
      background-color: #ccc;
      color: #7f7f7f;
    }
  }

  .send-later-button {
    display: block;
    text-align: center;
    background-color: #ed1c24;
    border: 0;
    width: auto;
    height: auto;
    padding: 6px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #bf151a;
    }

    &:disabled {
      background-color: #ccc;
      color: #7f7f7f;
    }
  }

  .actions {
    display: block;
    text-align: center;
    background-color: #ffffff;
    border: 0;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;

    &:hover {
      background: #f3f3f3;
    }
  }
`;
