import React from 'react';
import { BoxShadowUi } from './style';

interface IBoxShadowProps {
  children: React.ReactNode;
  className?: string;
}

const BoxShadow = ({ children, className }: IBoxShadowProps): JSX.Element => {
  return <BoxShadowUi className={className}>{children}</BoxShadowUi>;
};

export default BoxShadow;
