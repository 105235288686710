import { AxiosResponse } from 'axios';
import api from '../api';
import GatewayUserCreateDTO from './dtos/GatewayUserCreateDTO';
import IGatewayUserPaymentProfileCreateDTO from './dtos/GatewayUserPaymentProfileCreateDTO';
import GatewayUserPaymentProfileDeleteDTO from './dtos/GatewayUserPaymentProfileDeleteDTO';

const GatewayUser = {
  baseUri: '/gateway-user',

  findOrCreate(obj: GatewayUserCreateDTO): Promise<AxiosResponse> {
    return api.post(`${GatewayUser.baseUri}`, obj);
  },

  deletePaymentProfile(
    data: GatewayUserPaymentProfileDeleteDTO,
  ): Promise<AxiosResponse> {
    return api.delete(`${GatewayUser.baseUri}/payment-profile`, { data });
  },

  createPaymentProfile(
    data: IGatewayUserPaymentProfileCreateDTO,
  ): Promise<AxiosResponse> {
    return api.post(`${GatewayUser.baseUri}/payment-profile`, data);
  },
};

export default GatewayUser;
