import React from 'react';
import { Switch, Route } from 'react-router-dom';

import RouteComponent from '../../components/Route';

import AuthenticatedUser from '../Authenticated';
import Admin from './index';
import { AuthProvider } from '../../hooks/auth';

const Routes = (): JSX.Element => (
  <AuthProvider>
    <Switch>
      <Route path="/sso/authenticated/:hash" component={AuthenticatedUser} />
      <RouteComponent path="/" exact component={Admin} isPrivate />
      <RouteComponent path="/admin" exact component={Admin} isPrivate />
      <RouteComponent path="/admin/*" component={Admin} isPrivate />
    </Switch>
  </AuthProvider>
);

export default Routes;
