import { AxiosResponse } from 'axios';
import api from '../api';

const CheckoutService = {
  baseUri: '/checkout',

  getCheckout(id: string): Promise<AxiosResponse> {
    return api.get(`${CheckoutService.baseUri}/${id}`);
  },

  cancelCheckout(id: string): Promise<AxiosResponse> {
    return api.patch(`${CheckoutService.baseUri}/cancel/${id}`);
  },

  leaveCheckout(id: string): Promise<AxiosResponse> {
    return api.patch(`${CheckoutService.baseUri}/leave/${id}`);
  },

  deleteDocumentFromCheckout(
    checkoutId: string,
    documentIds: string[],
    cardBrands: string[],
  ): Promise<AxiosResponse> {
    return api.put(`${CheckoutService.baseUri}/delete-documents`, {
      checkoutId,
      documentIds,
      cardBrands,
    });
  },
};

export default CheckoutService;
