import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 650,
  },
});

export const TableUi = styled.div`
  overflow: hidden;
  width: 100%;
  display: block;

  .header-table {
    padding: 16px 0px;
    h2 {
      font-size: 24px;
      color: #9a9a9a;
      font-weight: 400;
    }
  }

  table {
    thead {
      tr {
        th {
          font-weight: 700;
          color: #9a9a9a;
        }
      }
    }
    tbody {
      .MuiTableRow-root.MuiTableRow-hover:hover {
        button {
          background: #f3f3f3;
        }
      }
      tr {
        td,
        .voucher {
          svg {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.58);
          }
        }
      }
    }
  }

  .details-total {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    justify-content: space-between;
    height: 50px;
  }

  .select-all {
    display: flex;
    flex-direction: flex-start;
  }

  .delete-icon {
    color: #676364;
  }

  .delete-button {
    min-width: 55px;
  }

  .totals {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }

  .checkboxes {
    padding: 0;
  }
`;
