import { AxiosResponse } from 'axios';
import api from '../api';

const url = '/webhook-systems';

export interface IWebhookSystem {
  id?: string;
  system: string;
  endpoint: string;
  type: string;
  authentication: string;
  secret: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: unknown;
  updatedBy?: unknown;
}

const WebhookSystemService = {
  /**
   * insert or update
   */
  update: ({
    system,
    endpoint,
    type,
    authentication,
    secret,
    id = undefined,
  }: IWebhookSystem): Promise<AxiosResponse> => {
    return api.post(url, {
      system,
      endpoint,
      type,
      authentication,
      secret,
      id,
    });
  },

  /**
   * list all
   */
  index: (): Promise<AxiosResponse> => {
    return api.get(url);
  },

  /**
   * delete one
   */
  delete: (id: string): Promise<AxiosResponse> => {
    return api.delete(`${url}/${id}`);
  },
};

export default WebhookSystemService;
