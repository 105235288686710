import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const NewCardModalUi = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
`;

export const ModalFormUi = styled.div`
  .line {
    display: flex;
    margin-bottom: 20px;

    .document-container {
      width: 100%;
    }
  }

  .subtitle {
    margin: 20px 0;
    font-size: 1rem;
    font-weight: 500;
  }

  .disclaimer {
    font-size: 15px;
    color: #6e6e6e;
    margin-bottom: 25px;
  }
`;

export const TextInputCardNumber = styled.div`
  .card-number-c {
    width: 100%;
    height: 40px;

    display: block;
    background: #f5f5f5;
    border-radius: 2px 2px 0px 0px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #d5d5d5;
    margin-right: 20px;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &::-webkit-input-placeholder::after {
      display: block;
      content: 'Line 2 Line 3';
    }

    &:hover {
      border-bottom: 2px solid #000;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid #3f51b5;
      pointer-events: none;
    }
  }
`;

export const TextInputCardName = styled(TextField)`
  width: 100%;
  height: 40px;

  display: block;
  background: #f5f5f5;
  border-radius: 2px 2px 0px 0px;

  input.MuiInputBase-input.MuiInput-input {
    padding: 14px 0 7px 5px;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #d5d5d5;
  }
`;

export const TextInputValidate = styled.div`
  .card-number-f {
    width: 100%;
    height: 40px;

    display: block;
    background: #f5f5f5;
    border-radius: 2px 2px 0px 0px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #d5d5d5;

    &:focus {
      outline: none;
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #3f51b5;
      pointer-events: none;
    }
  }

  .document {
    height: 40px;
    width: 100%;

    display: flex;
    background: #f5f5f5;
    border-radius: 2px 2px 0px 0px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #d5d5d5;
    padding-left: 5.5px;

    &:focus {
      outline: none;
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #3f51b5;
      /* pointer-events: none; */
    }
  }
`;

export const IGatewayUi = styled.div`
  display: flex;
  align-items: center;

  figure {
    display: inline-block;
    margin-left: 10px;

    img {
      width: 50px;
    }
  }
`;

export const NewCardActionsUi = styled.div`
  margin-right: 20px;

  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    box-shadow: none;
  }

  button {
    height: 45px;
    :not(:last-child) {
      margin-right: 10px;
    }
  }
`;
