import ICustomer from './ICustomerDTO';
import IDocument from './IDocumentDTO';

// eslint-disable-next-line no-shadow
export enum DocumentNumberMasks {
  CPF = '###.###.###-##',
  CNPJ = '##.###.###/####-##',
}

export default interface ICheckout {
  paymentCurrency: string;
  issuedCurrency: string;
  customer: ICustomer;
  documents: IDocument[];
  totalAmount: string;
  paymentAmount: string;
  successUrl: string;
  errorUrl: string;
  gateway: string;
  exchangeRate?: number;
  exchangeRateFormatted?: string;
  cardBrands?: string[];
  salesOrganization: string;
  login: string;
  email: string;
  totalIrfValue?: string;
  totalInterestAmount: string;
  totalLiquidAmount?: string;
  maxInstallments: number;
  transactionNumber?: string;
  transactionType?: string;
  application: string;
  session?: string;
}
