const sessionStorageService = {
  getAccessToken: (): string | null => {
    return sessionStorage.getItem('@epayments:token');
  },
  setAccessToken: (token: string): void => {
    sessionStorage.setItem('@epayments:token', token);
  },
  clearAllAccessToken: (): void => {
    sessionStorage.clear();
  },
};

export default sessionStorageService;
