/* eslint-disable no-nested-ternary */
import { RouteComponentProps } from 'react-router-dom';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import Button from '../../components/Button';

import { useTCS } from '../../hooks/tcs';
import { TermsAndConditionsResponse } from '../../services/terms-and-conditions/dto/termsAndConditionsResponse';
import { PdfView } from './styles';
import { TermsAndConditionsLanguages } from '../../services/terms-and-conditions/types';
import { TermsAndConditionTokenDTO } from '../../services/terms-and-conditions/dto/termsAndConditionTokenDTO';
import { TermsAndConditionsAcceptRequestDTO } from '../../services/terms-and-conditions/dto/termsAndConditionsAcceptRequestDTO';
import PdfViewer from '../../components/PdfViewer';

export type ITCSState = {
  tcsData: TermsAndConditionsResponse & TermsAndConditionTokenDTO;
};

const TermsAndConditions = ({ location }: RouteComponentProps): JSX.Element => {
  const { sendToAccept } = useTCS();

  const { tcsData } = location.state as ITCSState;

  const { t } = useTranslation('globals');
  const [source, setSource] = useState<string | null>(null);
  const [checkboxAccept, setCheckboxAccept] = useState(false);
  const [loadAccepted, setLoadAccepted] = useState(false);
  const [language, setLanguage] = useState<TermsAndConditionsLanguages>('en');

  const handleAccepted = async () => {
    setLoadAccepted(true);
    try {
      const payload: TermsAndConditionsAcceptRequestDTO = {
        name_user: tcsData.name_user,
        email_user: tcsData.email_user,
        login_user: tcsData.login_user,
        customers: tcsData.customers,
        portal_account_id: tcsData.portal_account_id,
        pdf_version: tcsData.version?.toString() || '',
        system_code: 'EPY',
        language,
      };

      await sendToAccept(payload);
    } catch (error) {
      setLoadAccepted(false);

      const { message } = error.response.data;
      if (message) {
        Swal.fire('Error!', message, 'error');
      } else {
        Swal.fire(
          'Error!',
          'Error while accepting Terms And Conditions',
          'error',
        );
      }
    }
  };

  const changeLanguagePdfView = (newLanguage: TermsAndConditionsLanguages) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
    }
  };

  useEffect(() => {
    const loadPdfView = async (code: string) => {
      setSource(null);
      if (tcsData.pdfs !== undefined && tcsData.pdfs.length > 0) {
        const sourceGet = tcsData.pdfs.filter((pdf) => pdf.code === code);
        setSource(
          /localhost/gi.test(window.location.host)
            ? sourceGet[0].source
            : `https://docs.google.com/gview?url=${sourceGet[0].source}?f=true&embedded=true`,
        );
      }
    };

    loadPdfView(language);
  }, [language, tcsData.pdfs]);

  const mapPdfs = () => {
    if (tcsData.pdfs !== undefined && tcsData.pdfs.length < 0) return null;

    return tcsData.pdfs?.map((e) => (
      <li key={e.code}>
        <button
          type="button"
          onClick={() => changeLanguagePdfView(e.code)}
          className={language === e.code ? 'active' : ''}
        >
          {e.name}
        </button>
      </li>
    ));
  };

  return (
    <PdfView>
      <Grid container>
        <Grid container direction="row" alignItems="center" justify="center">
          <Grid className="languages" item xs={12}>
            <div className="list-languages">
              <ul>{mapPdfs()}</ul>
            </div>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <PdfViewer source={source} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="baseline"
          className="acceptance"
        >
          <Grid item xs={12} md={9}>
            <FormControlLabel
              control={(() => (
                <Checkbox
                  className="checkbox-accept"
                  checked={checkboxAccept}
                  onChange={() => setCheckboxAccept(!checkboxAccept)}
                  name="checkedB"
                  color="primary"
                />
              ))()}
              label={t(
                'I have read, understood and accept the terms and conditions of the ANNEX TO THE GENERAL TERMS OF USE OF FLYEMBRAER.COM',
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              text={loadAccepted === false ? t('Accept Terms') : '...'}
              onClick={handleAccepted}
              disabled={!checkboxAccept || loadAccepted}
              className="tcs"
              type="button"
            />
          </Grid>
        </Grid>
      </Grid>
    </PdfView>
  );
};

export default TermsAndConditions;
