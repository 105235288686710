import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import * as am4core from '@amcharts/amcharts4/core';

import GlobalStyle from '../GlobalStyle';

import Routes from './routes';

am4core.addLicense(`${process.env.REACT_APP_AMCHART4_LICENSE}`);
am4core.options.commercialLicense = true;

const App = (): JSX.Element => (
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={10}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Routes />
    </SnackbarProvider>
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
