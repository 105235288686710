import styled from 'styled-components';

export const SummaryUi = styled.div`
  --color-gray-medium: #d5d5d5;

  display: block;
  width: 100%;
  border: 1px solid #eaeaea;
  background-color: #f5f5f5;
  padding: 64px 0 30px;

  .summary {
    padding: 16px;

    & *:not(:last-child) {
      margin-bottom: 12px;
    }

    &__customer {
      padding-bottom: 12px;
      border-bottom: 1px dashed var(--color-gray-medium);
    }

    &__values {
      padding-bottom: 12px;
      border-bottom: 1px solid var(--color-gray-medium);
    }

    h4 {
      font-size: 12px;
      font-weight: 700;
    }

    h5 {
      font-size: 13px;
      font-weight: 400;
      color: #6e6e6e;
    }

    h6 {
      font-size: 13px;
      line-height: 15.23px;
      text-align: right;
      color: #005baf;
      font-weight: 700;
    }

    .link {
      font-size: 13px;
      text-align: right;

      a:visited {
        color: blue;
      }
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
  }
`;
