import styled from 'styled-components';

export const HeaderUi = styled.header`
  height: 53px;
  background-color: #005baf;

  .container {
    padding: 0 30px;
  }

  .logo-epayments {
    height: 53px;
    display: flex;
    justify-content: flex-start;
    img {
      width: 150px;
    }
  }

  .logo-embraer {
    height: 53px;
    display: flex;
    justify-content: flex-end;
    img {
      width: 150px;
      filter: brightness(0) invert(1);
    }
  }
`;
