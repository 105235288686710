import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { PaymentConfirmationUi } from './style';
import { usePayment } from '../../../hooks/payment';

const TicketRegistration = (): JSX.Element => {
  const { t } = useTranslation('checkout');
  const { btnConfirm, handleSubmitPayment } = usePayment();

  const handleSubmit = () => {
    handleSubmitPayment();
  };
  return (
    <PaymentConfirmationUi>
      <Grid container className="paper">
        <Grid item xs={12}>
          <h4>{t('Bill Instructions Title')}</h4>
        </Grid>
        <Grid item xs={12}>
          <p>{t('Bill Instructions')}</p>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={10} />
        <Grid item xs={12} md={2}>
          <Button
            disabled={btnConfirm}
            text={t('Bill Download')}
            className={
              btnConfirm ? 'summary-confirm-disabled' : 'summary-confirm-active'
            }
            type="button"
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </PaymentConfirmationUi>
  );
};

export default TicketRegistration;
