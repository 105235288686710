import styled from 'styled-components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      border: 'none',
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      border: 'none',
    },
    appBar: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: '#F1F3F4',
      border: 'none',
      height: 67,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      backgroundColor: '#F1F3F4',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.2)',
      height: 67,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#F1F3F4',
      boxShadow: 'none',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      flexGrow: 1,
      padding: theme.spacing(3),
      width: '100%',
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      flexGrow: 1,
      width: '100%',
    },
  }),
);

export const HomeRoutesStyle = styled.div``;
