export type Profile =
  | 'EPY_SUPPORT'
  | 'EPY_FINANCIER'
  | 'EPY_BASIC_USER'
  | 'OLP_FINANCIER'
  | 'OLP_SUPPORT'
  | 'OLP_BASIC_USER';

export type ProfileGroup = Profile[];

export const SUPPORT: ProfileGroup = ['EPY_SUPPORT', 'OLP_SUPPORT'];
export const ADMIN: ProfileGroup = [
  'EPY_SUPPORT',
  'EPY_FINANCIER',
  'OLP_FINANCIER',
  'OLP_SUPPORT',
];
export const BASIC: ProfileGroup = [
  'EPY_BASIC_USER',
  'EPY_SUPPORT',
  'EPY_FINANCIER',
  'OLP_BASIC_USER',
  'OLP_SUPPORT',
  'OLP_FINANCIER',
];

type IUserProfiles = {
  profileAccept: ProfileGroup;
  profile: string[];
};

const checkProfile = ({ profile, profileAccept }: IUserProfiles): boolean => {
  const result = profile.some((p) => profileAccept.includes(p as Profile));
  return result;
};

export default checkProfile;
