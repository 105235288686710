/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const JPMorganEncryption = (callback?: (...args: unknown[]) => unknown) => {
  const scriptId = 'jpmorgan-encryption';
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');

    script.type = 'text/javascript';

    const jpMorganKey = process.env.REACT_APP_JPMORGAN_KEY;

    script.src = `https://safetechpageencryptionvar.chasepaymentech.com/pie/v1/${jpMorganKey}/getkey.js`;

    script.id = scriptId;

    document.head.appendChild(script);
    script.onload = () => {
      const t: any = window;
      if (t.ProtectPANandCVV) {
        if (callback) callback();
      }
    };
  }

  if (existingScript && callback) callback();
};

export default JPMorganEncryption;
