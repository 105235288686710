import { AxiosResponse } from 'axios';
import api from '../api';

type IFindAllProps = {
  page?: number;
  limit?: number;
};

type ISaveNewGatewayProps = {
  title: string;
  nameClass: string;
  id?: string;
};

const gateways = {
  findAll: async ({
    page = 1,
    limit = 10,
  }: IFindAllProps): Promise<AxiosResponse> => {
    const queryParams = `?page=${page}&limit=${limit}`;
    return api.get(`/gateway${queryParams}`);
  },

  saveNewGateway: async ({
    title,
    nameClass,
  }: ISaveNewGatewayProps): Promise<AxiosResponse> => {
    return api.post(`/gateway`, {
      title,
      nameClass,
    });
  },

  updateGateway: async ({
    title,
    nameClass,
    id,
  }: ISaveNewGatewayProps): Promise<AxiosResponse> => {
    return api.put(`/gateway/${id}`, {
      title,
      nameClass,
    });
  },

  deleteGateway: async (id: string): Promise<void> => {
    await api.delete(`/gateway/${id}`);
  },
};

export default gateways;
