import React from 'react';
// import Lottie from 'react-lottie';
// import flyingAirplane from '../../assets/lottie/flying-airplane.json';
import imgLoadingEmb from '../../assets/images/loading.gif';

interface ILoadingProps {
  height?: string;
  width?: number;
  justifyContent?: string;
}

const Loading = ({
  height = '500px',
  width = 100,
  justifyContent = 'center',
}: ILoadingProps): JSX.Element => {
  return (
    <div
      style={{
        height,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent,
      }}
    >
      <img
        src={imgLoadingEmb}
        alt="Loading Embraer"
        height={width}
        width={width}
      />
      {/* <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: flyingAirplane,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={lHeight}
        width={width}
      /> */}
    </div>
  );
};

export default Loading;
