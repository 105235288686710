import styled from 'styled-components';

export const PdfViewerUi = styled.section`
  .pdf-view {
    padding: 14px;
    background-color: #525659;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 65vh;
  }

  @media screen and (max-width: 1024px) {
    .pdf-view {
      background-color: none !important;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;
