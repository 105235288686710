import styled from 'styled-components';

export const BoxShadowGraph = styled.div`
  padding: 16px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  .grafico-stock-home {
    h2 {
      font-size: 18px;
      font-weight: 400;
      color: #b2b2b2;
    }
  }
`;
