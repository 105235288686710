import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

import { BoxShadowGraph } from './style';
import api from '../../../services/api';
import Loading from '../../Loading/index';

interface IGraphBySalesOrganizationProps {
  title: string;
  width: string;
  height: string;
}

const GraphBySalesOrganization = ({
  title,
  width,
  height,
}: IGraphBySalesOrganizationProps): JSX.Element => {
  const [loading, setLoading] = useState(true);

  function mountGraph(data: any) {
    setLoading(false);
    am4core.useTheme(am4themesAnimated);
    const chart = am4core.create('chartdivpiesales', am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.legend = new am4charts.Legend();
    chart.data = data;

    const series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = 'total';
    series.dataFields.category = 'salesOrganization';
  }

  useEffect(() => {
    api.get('/dashboard/by-sales-organization').then((response) => {
      mountGraph(response.data.body);
    });
  }, []);

  return (
    <BoxShadowGraph>
      <div className="grafico-stock-home">
        <h2>{title}</h2>
        {loading ? (
          <Loading height="350px" />
        ) : (
          <div
            id="chartdivpiesales"
            style={{ width: `${width}`, height: `${height}` }}
          />
        )}
      </div>
    </BoxShadowGraph>
  );
};

export default GraphBySalesOrganization;
