import styled from 'styled-components';

export const PdfView = styled.section`
  display: block;
  width: 100%;
  min-height: 100vh;
  max-width: 50%;
  margin: 0 auto;

  .list-languages {
    display: block;
    width: 100%;
    margin-bottom: 32px;

    ul {
      padding: 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;

      button {
        padding: 10px 30px;
        background-color: #b5b5b5;
        margin: 0px 4px;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        border: none;
      }

      button.active {
        background-color: #1655a0;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .pdf-view {
    height: 65vh;
  }

  .languages {
    margin-bottom: 10px;
  }

  .acceptance {
    margin-top: 15px;
  }

  .MuiToggleButton-root {
    background-color: rgba(35, 73, 121, 0.5);
    color: #fff;
    transition: background-color 0.3s;
    min-width: 150px;

    &.Mui-selected {
      background-color: rgba(35, 73, 121, 0.9);
      color: #fff;

      &:hover {
        background-color: rgba(35, 73, 121, 0.9);
      }
    }

    &:hover {
      background-color: rgba(35, 73, 121, 0.9);
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
  }

  @media screen and (min-width: 1024.01px) and (max-width: 1320px) {
    width: 100%;
    max-width: 70%;
    margin: 0 auto;
  }
`;
