import styled from 'styled-components';

export const PaymentOrderInfoUI = styled.div`
  font-size: 13px;

  p {
    padding: 0;
    color: #095e3a;
    display: none;
  }

  .payment-order-data {
    margin-bottom: 10px;
  }

  .display-flex {
    display: flex;
  }

  .icon {
    font-size: 18px;
    margin: 0 5px;
    cursor: pointer;
  }

  .MuiAlert-standardWarning {
    background-color: #fff7cd;
    border: 1px solid #ffd699;
    border-radius: 3px;
    width: 100%;
  }

  .MuiAlert-root {
    align-items: center;
  }

  .MuiAlert-message {
    max-width: 90%;
  }
`;
