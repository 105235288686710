import React from 'react';
import Grid from '@material-ui/core/Grid';
import { CustomerUi } from './style';

interface ICustomerProps {
  name?: string;
}

const Customer = ({ name = '' }: ICustomerProps): JSX.Element => {
  return (
    <Grid item xs={12}>
      <CustomerUi>
        <h2>{name}</h2>
      </CustomerUi>
    </Grid>
  );
};

export default Customer;
