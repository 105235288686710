import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { RiBankCard2Line } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { CardItemUi } from './style';
import Flags from '../Flags';

import { usePayment } from '../../../hooks/payment';

import NewCardModal from '../NewCardModal';
import CardMenuButton from '../CardMenuButton';
import GatewayUser from '../../../services/gateway-user/GatewayUserService';
import { BillingInformation } from '../../../services/payment/interfaces/ICheckoutPaymentDTO';
import IDocument from '../../../services/checkout/interfaces/IDocumentDTO';

export interface ICard {
  card: string;
  customerPaymentProfileId?: string;
  brand?: string;
  cvv?: string;
  holderName?: string;
  validate?: string;
  billingInformation?: BillingInformation;
}

export interface IGatewayUserData {
  salesOrganization: string;
  paymentCurrency: string;
  login: string;
  email: string;
  gateway: string;
  maxInstallments: number;
  documents: IDocument[];
}

interface ICardsList {
  cards: ICard[];
  setCards(v: ICard[]): void;
  gatewayUserData: IGatewayUserData;
}

const CardList = ({
  gatewayUserData,
  cards,
  setCards,
}: ICardsList): JSX.Element => {
  const { t } = useTranslation('checkout');
  const snackbar = useSnackbar();

  const {
    setNameCard,
    setNumCard,
    numCard,
    setValidate,
    setUserProfile,
    setUserPaymentProfile,
    setCvv,
    userProfile,
    setCardBrand,
    setBillingInformation,
  } = usePayment();

  const [newCardOpenModal, setNewCardOpenModal] = useState(false);
  const [showBillingForm, setShowBillingForm] = useState(false);

  const handleSelectCreditCardToPay = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCvv('');
    setNumCard(event.target.value);

    const cardSelected = (c: ICard) => c.card === event.target.value;
    const card = cards.find(cardSelected);

    if (!card) {
      snackbar.enqueueSnackbar(t('Please select a card to pay!'), {
        variant: 'error',
      });
      return;
    }

    setPaymentData(card);
  };

  const handleEdit = (c: ICard) => {
    setPaymentData(c);
    setNewCardOpenModal(true);
  };

  const handleOpenNewCardModal = () => {
    clearPaymentData();
    setNewCardOpenModal(true);
  };

  const setPaymentData = (cardToPay: ICard) => {
    setNumCard(cardToPay.card);

    const {
      validate,
      holderName,
      customerPaymentProfileId,
      brand,
      billingInformation,
    } = cardToPay as ICard;

    setValidate(validate || '');
    setNameCard(holderName || '');
    setUserPaymentProfile(customerPaymentProfileId || '');
    setCardBrand(brand || '');
    setBillingInformation(billingInformation);
  };

  const clearPaymentData = () => {
    setNumCard('');
    setValidate('');
    setNameCard('');
    setUserPaymentProfile('');
    setCardBrand('');
    setCvv('');
    setBillingInformation(undefined);
  };

  const handleDeleteCard = async (cardNumber: string) => {
    const cardSelected = (c: ICard) => c.card === cardNumber;
    const card = cards.find(cardSelected);

    try {
      if (card?.customerPaymentProfileId) {
        await GatewayUser.deletePaymentProfile({
          customerPaymentProfileId: card.customerPaymentProfileId,
          customerProfileId: userProfile || '',
          paymentCurrency: gatewayUserData.paymentCurrency,
          salesOrganization: gatewayUserData.salesOrganization,
        });
      }

      if (cardNumber === numCard) {
        clearPaymentData();
      }

      setCards(cards.filter((c) => c.card !== cardNumber));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  useEffect(() => {
    GatewayUser.findOrCreate({
      currency: gatewayUserData.paymentCurrency,
      email: gatewayUserData.email,
      login: gatewayUserData.login,
      salesOrganization: gatewayUserData.salesOrganization,
    })
      .then((r) => {
        const { body } = r.data;

        if (!body || !body.gatewayUser.userProfile) {
          return;
        }

        setShowBillingForm(true);

        setUserProfile(body.gatewayUser.userProfile);

        if (!body.cards || body.cards.length === 0) {
          return;
        }

        setCards(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          body.cards.map((e: any) => ({ ...e, validate: e.cardExpiration })),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(JSON.stringify(error));
      });
  }, [gatewayUserData, setUserProfile, setCards]);

  const labelList = ({ card, brand }: ICard) => (
    <>
      <span className="card-brand">
        {brand ? (
          <Flags cardBrands={[brand]} show />
        ) : (
          <RiBankCard2Line color="#94BFF3" size={18} />
        )}
      </span>
      <span className="card-brand__name">
        {(brand ? `${brand}  ` : '') + '****'.concat(card.slice(-4))}
      </span>
    </>
  );

  return (
    <>
      <FormControl fullWidth>
        <RadioGroup
          aria-label="payment profile"
          name="paymentProfile"
          value={numCard}
          onChange={handleSelectCreditCardToPay}
        >
          {cards.map((card) => (
            <CardItemUi active={card.card === numCard} key={card.card}>
              <Grid container alignItems="center">
                <Grid item xs={10} md={11}>
                  <FormControlLabel
                    value={card.card}
                    control={
                      <Radio color="primary" style={{ marginLeft: '10px' }} />
                    }
                    label={labelList(card)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <CardMenuButton
                    checkout={gatewayUserData}
                    handleDelete={() => handleDeleteCard(card.card)}
                    handleEdit={() => handleEdit(card)}
                  />
                </Grid>
              </Grid>
            </CardItemUi>
          ))}
        </RadioGroup>
      </FormControl>
      <CardItemUi onClick={handleOpenNewCardModal}>
        <Grid container className="new-card">
          <Grid item xs={3} sm={3} md={2} lg={1}>
            <div className="card-brand">
              <RiBankCard2Line color="#94BFF3" size={18} />
            </div>
          </Grid>
          <Grid item xs={9}>
            <p>{t('Add New Card')}</p>
          </Grid>
        </Grid>
      </CardItemUi>
      <NewCardModal
        handleGoToPayment={setPaymentData}
        open={newCardOpenModal}
        setOpen={setNewCardOpenModal}
        gatewayUserCards={cards}
        setGatewayUserCards={setCards}
        gateway={gatewayUserData.gateway}
        showBillingForm={showBillingForm}
      />
    </>
  );
};

export default CardList;
