import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { BankInfoUI } from './style';
import BankingService from '../../../../services/banking/banking-service';
import { PaymentMethods } from '../../../../services/payment/interfaces/ICheckoutPaymentDTO';
import { BankInformationDTO } from '../../../../services/banking/dtos/bank-info-dto';

export interface BankInfoProps {
  currency: string;
  salesOrganization: string;
  type: PaymentMethods;
}

const BankInfo = ({
  currency,
  salesOrganization,
  type,
}: BankInfoProps): JSX.Element => {
  const { t } = useTranslation('checkout');
  const { goBack } = useHistory();

  const [bankInfo, setBankInfo] = useState<undefined | BankInformationDTO>();

  useEffect(() => {
    BankingService.findBankInformation({
      currency,
      salesOrganization,
      type,
    })
      .then((r) => {
        setBankInfo(r.data.body.bankInformation);
      })
      .catch((err) => {
        Swal.fire({
          title: 'Oops!',
          text:
            err.response.data.message ||
            t('Error trying to get bank information'),
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          goBack();
        });
      });
  }, [currency, salesOrganization, type, goBack, t]);

  return (
    <BankInfoUI>
      <Grid container>
        <Grid item xs={12} className="display-flex">
          <AccountBalanceIcon className="icon" />
          <Box fontWeight="fontWeightBold">{t('Bank Information')}</Box>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className="bank-info-data"
        >
          <Grid item xs={3}>
            <Box fontWeight="fontWeightBold">{`${t('Beneficiary')}:`}</Box>
          </Grid>
          <Grid item xs={9}>
            <Box color="#6E6E6E">{bankInfo?.beneficiaryName}</Box>
          </Grid>

          <Grid item xs={3}>
            <Box fontWeight="fontWeightBold">
              {`${t('Beneficiary Address')}:`}
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box color="#6E6E6E">{bankInfo?.beneficiaryAddressOne}</Box>
          </Grid>

          {bankInfo?.beneficiaryAddressTwo ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">
                  {`${t('Beneficiary Address')} ${t('Two')}:`}
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.beneficiaryAddressTwo}</Box>
              </Grid>
            </>
          ) : null}

          {bankInfo?.beneficiaryAddressThree ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">
                  {`${t('Beneficiary Address')} ${t('Three')}:`}
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.beneficiaryAddressThree}</Box>
              </Grid>
            </>
          ) : null}

          <Grid item xs={3}>
            <Box fontWeight="fontWeightBold">{`${t('Bank')}:`}</Box>
          </Grid>
          <Grid item xs={9}>
            <Box color="#6E6E6E">{bankInfo?.bankName}</Box>
          </Grid>

          {bankInfo?.account ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">{`${t('Account')}:`}</Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.account}</Box>
              </Grid>
            </>
          ) : null}

          {bankInfo?.agency ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">{`${t('Agency')}:`}</Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.agency}</Box>
              </Grid>
            </>
          ) : null}

          {bankInfo?.iban ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">{`${t('Iban')}:`}</Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.iban}</Box>
              </Grid>
            </>
          ) : null}
          {bankInfo?.aba ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">ABA:</Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.aba}</Box>
              </Grid>
            </>
          ) : null}

          {bankInfo?.swift ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">SWIFT:</Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.swift}</Box>
              </Grid>
            </>
          ) : null}

          {bankInfo?.routing ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">
                  {`${t('Domestic Routing')}`}
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.routing}</Box>
              </Grid>
            </>
          ) : null}

          {bankInfo?.bankAddress ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">{`${t('Bank Address')}:`}</Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.bankAddress}</Box>
              </Grid>
            </>
          ) : null}

          {bankInfo?.cnpj ? (
            <>
              <Grid item xs={3}>
                <Box fontWeight="fontWeightBold">CNPJ:</Box>
              </Grid>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.cnpj}</Box>
              </Grid>
            </>
          ) : null}

          {bankInfo?.message ? (
            <>
              <Grid item xs={9}>
                <Box color="#6E6E6E">{bankInfo?.message}</Box>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </BankInfoUI>
  );
};

export default BankInfo;
