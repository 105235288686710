const accepted: { [key: string]: string[] } = {
  GetnetGateway: ['mastercard', 'visa', 'americanexpress', 'hipercard'],
  CieloGateway: [
    'mastercard',
    'visa',
    'americanexpress',
    'hipercard',
    'dinersclub',
    'jcb',
  ],
  AuthorizeGateway: [
    'mastercard',
    'visa',
    'americanexpress',
    'dinersclub',
    'jcb',
    'discover',
  ],
  AuthorizeGatewayUser: [
    'mastercard',
    'visa',
    'americanexpress',
    'dinersclub',
    'jcb',
    'discover',
  ],
  FivServDataGatewayUser: [
    'mastercard',
    'visa',
    'americanexpress',
    'dinersclub',
    'jcb',
    'discover',
  ],
  JPMorganGateway: [
    'mastercard',
    'visa',
    'americanexpress',
    'dinersclub',
    'jcb',
    'discover',
  ],
  JPMorganGatewayUser: [
    'mastercard',
    'visa',
    'americanexpress',
    'dinersclub',
    'jcb',
    'discover',
  ],
};

const acceptedBysalesOrganization: { [key: string]: string[] } = {
  LBG1: ['visa', 'mastercard'],
};

export default (gateway: string, salesOrganization: string): string[] => {
  const brands = accepted[gateway] || [];
  if (acceptedBysalesOrganization[salesOrganization]) {
    return brands.filter((brand) =>
      acceptedBysalesOrganization[salesOrganization].includes(brand),
    );
  }
  return brands;
};
